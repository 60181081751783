var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory", attrs: { id: "salesOrd" } },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("SALES ORDER")))]
            )
          ]),
          [
            !_vm.isAdvancedSearch
              ? _c(
                  "el-row",
                  { staticClass: "topFromSearch" },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: { "align-self": "flex-end" },
                        attrs: { xs: 24, sm: 21, md: 21, lg: 21, xl: 21 }
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "550px" },
                          attrs: {
                            placeholder: _vm.$t(
                              "PLEASE ENTER THE KEYWORDS TO BE SEARCHED"
                            )
                          },
                          model: {
                            value: _vm.SeaCodeVal,
                            callback: function($$v) {
                              _vm.SeaCodeVal = $$v
                            },
                            expression: "SeaCodeVal"
                          }
                        }),
                        _c(
                          "el-badge",
                          {
                            staticStyle: {
                              "margin-right": "18px",
                              "margin-left": "18px"
                            },
                            attrs: { value: _vm.selectBadge }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  icon: "iconfont shaixuan2",
                                  size: "mini",
                                  title: _vm.$t("Display Filter")
                                },
                                on: {
                                  click: function($event) {
                                    _vm.isAdvancedSearch = true
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Filter")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              title: _vm.$t("Search"),
                              icon: "iconfont sousuo",
                              size: "mini",
                              type: "primary"
                            },
                            on: { click: _vm.search }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Search")))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-top": "20px",
                          "text-align": "right",
                          "align-self": "flex-end"
                        },
                        attrs: { xs: 24, sm: 3, md: 3, lg: 3, xl: 3 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont daochu2",
                              size: "mini",
                              title: _vm.$t("Export"),
                              plain: ""
                            },
                            on: { click: _vm.exportOrder }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Export")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isAdvancedSearch
              ? _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { height: "auto" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.SeaCodeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("KEYWORDS")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.SeaCodeVal,
                                callback: function($$v) {
                                  _vm.SeaCodeVal = $$v
                                },
                                expression: "SeaCodeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.soVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("SO")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.soVal,
                                callback: function($$v) {
                                  _vm.soVal = $$v
                                },
                                expression: "soVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.codeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("POProductCode")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.codeVal,
                                callback: function($$v) {
                                  _vm.codeVal = $$v
                                },
                                expression: "codeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.fromVal.ids.length == 0
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);",
                                attrs: { id: "venSpan" }
                              },
                              [_vm._v(_vm._s(_vm.$t("FROM")) + ":")]
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  "collapse-tags": "",
                                  placeholder: ""
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  },
                                  change: _vm.venchang
                                },
                                model: {
                                  value: _vm.fromVal.ids,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fromVal, "ids", $$v)
                                  },
                                  expression: "fromVal.ids"
                                }
                              },
                              _vm._l(this.fromData, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.isCanAgent
                          ? _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style:
                                      _vm.otherStatusVal == null ||
                                      _vm.otherStatusVal == ""
                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                        : "top: -10px;left: 20px;transform: translate(0, 0);",
                                    attrs: { id: "otherStatusSpan" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("STATE")) + ":")]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "collapse-tags": "",
                                      placeholder: "",
                                      clearable: ""
                                    },
                                    on: {
                                      focus: function($event) {
                                        return _vm.focusDiv($event)
                                      },
                                      blur: function($event) {
                                        return _vm.blurDiv($event)
                                      },
                                      change: _vm.otherStatuschang
                                    },
                                    model: {
                                      value: _vm.otherStatusVal,
                                      callback: function($$v) {
                                        _vm.otherStatusVal = $$v
                                      },
                                      expression: "otherStatusVal"
                                    }
                                  },
                                  _vm._l(this.traingStatusData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isCanAgent
                          ? _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style:
                                      _vm.traingStatusVal == null ||
                                      _vm.traingStatusVal == ""
                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                        : "top: -10px;left: 20px;transform: translate(0, 0);",
                                    attrs: { id: "traingStatusSpan" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("STATE")) + ":")]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "collapse-tags": "",
                                      placeholder: "",
                                      clearable: ""
                                    },
                                    on: {
                                      focus: function($event) {
                                        return _vm.focusDiv($event)
                                      },
                                      blur: function($event) {
                                        return _vm.blurDiv($event)
                                      },
                                      change: _vm.traingStatuschang
                                    },
                                    model: {
                                      value: _vm.traingStatusVal,
                                      callback: function($$v) {
                                        _vm.traingStatusVal = $$v
                                      },
                                      expression: "traingStatusVal"
                                    }
                                  },
                                  _vm._l(this.traingStatusData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.businessnoinVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                                  ) + ":"
                                )
                              ]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.businessnoinVal,
                                callback: function($$v) {
                                  _vm.businessnoinVal = $$v
                                },
                                expression: "businessnoinVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.businessnooutVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")
                                  ) + ":"
                                )
                              ]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.businessnooutVal,
                                callback: function($$v) {
                                  _vm.businessnooutVal = $$v
                                },
                                expression: "businessnooutVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.batchVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("POBATCH")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.batchVal,
                                callback: function($$v) {
                                  _vm.batchVal = $$v
                                },
                                expression: "batchVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.beginTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("ORDERBEINGTIME")) + ":")]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", "prefix-icon": "" },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.beginTimeVal,
                                callback: function($$v) {
                                  _vm.beginTimeVal =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "beginTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.endTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("ORDERENDTIME")) + ":")]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", "prefix-icon": "" },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.endTimeVal,
                                callback: function($$v) {
                                  _vm.endTimeVal =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "endTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.UTbeginTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("UPDATE START DATE")) + ":"
                                )
                              ]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", "prefix-icon": "" },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.UTbeginTimeVal,
                                callback: function($$v) {
                                  _vm.UTbeginTimeVal =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "UTbeginTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.UTendTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("UPDATE END DATE")) + ":")]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", "prefix-icon": "" },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.UTendTimeVal,
                                callback: function($$v) {
                                  _vm.UTendTimeVal =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "UTendTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.colorVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("COLOR")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.colorVal,
                                callback: function($$v) {
                                  _vm.colorVal = $$v
                                },
                                expression: "colorVal"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isAdvancedSearch
              ? _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "align-self": "flex-end"
                        },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              plain: "",
                              icon: "iconfont shaixuan1",
                              size: "mini",
                              title: _vm.$t("Hidden Filter")
                            },
                            on: {
                              click: function($event) {
                                _vm.isAdvancedSearch = false
                                _vm.getSelectBadge()
                              }
                            }
                          },
                          [_vm._v("  " + _vm._s(_vm.$t("Hidden Filter")) + " ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              title: _vm.$t("Search"),
                              icon: "iconfont sousuo",
                              size: "mini",
                              type: "primary"
                            },
                            on: { click: _vm.search }
                          },
                          [_vm._v("  " + _vm._s(_vm.$t("Search")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont daochu2",
                              size: "mini",
                              title: _vm.$t("Export"),
                              plain: ""
                            },
                            on: { click: _vm.exportOrder }
                          },
                          [_vm._v(_vm._s(_vm.$t("Export")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("avue-crud", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "crud",
              staticClass: "pvhTable",
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                "row-style": _vm.rowStyle,
                "cell-style": _vm.cellStyle,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "selection-change": _vm.selectionChange,
                "current-change": _vm.currentChange,
                "row-update": _vm.handleUpdate,
                "sort-change": _vm.sortChange
              },
              scopedSlots: _vm._u([
                {
                  key: "SpeedHeader",
                  fn: function(ref) {
                    var column = ref.column
                    return [
                      _c("label", [_vm._v(_vm._s(column.label))]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "bottom" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("saleTip")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _c("i", {
                            staticClass: "el-icon-warning",
                            staticStyle: {
                              "margin-left": "3px",
                              "font-size": "14px"
                            }
                          })
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "Speed",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "light", placement: "bottom-start" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "500",
                                    color: "#000",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("To do list for this order")
                                    ) + ":"
                                  )
                                ]
                              ),
                              _vm._l(
                                row.PendingSummaryAlls.CurrentSummaryInfos,
                                function(item, index) {
                                  return _c("div", { key: index }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("No.")) +
                                          _vm._s(item.Index) +
                                          "(" +
                                          _vm._s(item.BusinessNoIn) +
                                          ")：" +
                                          _vm._s(_vm.$t("to do a total"))
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "600",
                                          color: "#ff6a00",
                                          "font-size": "12px",
                                          "margin-left": "5px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.Count))]
                                    )
                                  ])
                                }
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "500",
                                    color: "#000",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "To do list for Associate purchasing"
                                      )
                                    ) + ":"
                                  )
                                ]
                              ),
                              _vm._l(
                                row.PendingSummaryAlls.OtherSummaryInfos,
                                function(item, index) {
                                  return _c("div", { key: index }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("No.")) +
                                          _vm._s(item.Index) +
                                          "(" +
                                          _vm._s(item.BusinessNoIn) +
                                          ")：" +
                                          _vm._s(_vm.$t("to do a total"))
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "600",
                                          color: "#ff6a00",
                                          "font-size": "12px",
                                          "margin-left": "5px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.Count))]
                                    )
                                  ])
                                }
                              )
                            ],
                            2
                          ),
                          _c("el-link", { attrs: { type: "warning" } }, [
                            _vm._v(_vm._s(row.Speed))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "TrackingApproveStatus",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-popover",
                        { attrs: { trigger: "hover", placement: "top" } },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "Statetip_" + row.TrackingApproveStatus
                                  )
                                )
                            )
                          ]),
                          _c(
                            "div",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [_vm._v(_vm._s(_vm.formatterColumn(row)))]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      row.ApproveStatus < 650 && row.LockedStatus != 605
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-edit-outline",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Edit")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.rowEdit(row, index)
                              }
                            }
                          })
                        : _vm._e(),
                      row.ApproveStatus >= 650 || row.LockedStatus == 605
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-s-order",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Look Over")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.rowView(row, index)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.isCanAgent &&
                      row.ApproveStatus < 650 &&
                      row.LockedStatus != 605
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-delete-solid",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Delete")
                            },
                            on: {
                              click: function($event) {
                                return _vm.delPur(row)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "DestinationCountryHeader",
                  fn: function(ref) {
                    var column = ref.column
                    return [
                      _c("label", [_vm._v(_vm._s(column.label))]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "bottom" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("MARKET EXPLAIN")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _c("i", {
                            staticClass: "el-icon-warning",
                            staticStyle: {
                              "margin-left": "3px",
                              "font-size": "14px"
                            }
                          })
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "expand",
                  fn: function(props) {
                    return [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: props.row.Details,
                            "header-cell-style": _vm.tableHeaderColor,
                            "row-class-name": _vm.tableRowClassName
                          }
                        },
                        [
                          _c("el-table-column", { attrs: { type: "index" } }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Speed",
                              align: "center",
                              label: _vm.$t("DEGREE OF COMPLETION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var index = ref.index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom-start"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column"
                                                  }
                                                },
                                                [
                                                  row.ProcessSummarys.length ==
                                                  0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "500",
                                                            color: "#000",
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "To do list for this order"
                                                              )
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "nothing"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  row.ProcessSummarys.length !=
                                                  0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "500",
                                                            color: "#000",
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "To do list for this order"
                                                              )
                                                            ) + ":"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    row.ProcessSummarys,
                                                    function(item, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "600",
                                                                color: "#000",
                                                                "font-size":
                                                                  "12px",
                                                                "margin-left":
                                                                  "5px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatColumn(
                                                                    item.Type
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          item.ProcessName !=
                                                          null
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    color:
                                                                      "#000",
                                                                    "font-size":
                                                                      "12px",
                                                                    "margin-left":
                                                                      "5px",
                                                                    "margin-right":
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    ":" +
                                                                      _vm._s(
                                                                        item.ProcessName
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.Type ==
                                                          "NoUpinfo"
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    "font-size":
                                                                      "10px",
                                                                    "margin-left":
                                                                      "5px",
                                                                    "margin-right":
                                                                      "5px"
                                                                  },
                                                                  attrs: {
                                                                    type: "text"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.upload(
                                                                        row,
                                                                        _vm.$index,
                                                                        size,
                                                                        type
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Click to fill in"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.Type ==
                                                          "NoWriteComponent"
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    "font-size":
                                                                      "10px",
                                                                    "margin-left":
                                                                      "5px",
                                                                    "margin-right":
                                                                      "5px"
                                                                  },
                                                                  attrs: {
                                                                    type: "text"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.fibred(
                                                                        row,
                                                                        _vm.$index,
                                                                        size,
                                                                        type
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Click to fill in"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.Type ==
                                                          "NoRelation"
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    "font-size":
                                                                      "10px",
                                                                    "margin-left":
                                                                      "5px",
                                                                    "margin-right":
                                                                      "5px"
                                                                  },
                                                                  attrs: {
                                                                    type: "text"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.rele(
                                                                        row,
                                                                        _vm.$index,
                                                                        size,
                                                                        type
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Click to fill in"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  row.DownPoProcessSummarys
                                                    .length == 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "500",
                                                            color: "#000",
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "To do list for Associate purchasing"
                                                              )
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "nothing"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  row.DownPoProcessSummarys
                                                    .length != 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "500",
                                                            color: "#000",
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "To do list for Associate purchasing"
                                                              )
                                                            ) + ":"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    row.DownPoProcessSummarys,
                                                    function(item, index) {
                                                      return _c(
                                                        "div",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "600",
                                                                color: "#000",
                                                                "font-size":
                                                                  "12px",
                                                                "margin-left":
                                                                  "5px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.Type
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          item.ProcessName != ""
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    color:
                                                                      "#000",
                                                                    "font-size":
                                                                      "12px",
                                                                    "margin-left":
                                                                      "5px",
                                                                    "margin-right":
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    ":" +
                                                                      _vm._s(
                                                                        item.ProcessName
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          ),
                                          row.IsRelatePO
                                            ? _c(
                                                "el-link",
                                                { attrs: { type: "warning" } },
                                                [_vm._v(_vm._s(row.Speed))]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom-start"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column"
                                                  }
                                                },
                                                [
                                                  row.IsRelatePO == false
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "500",
                                                            color: "#000",
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "SO not relate PO"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "icon" }, [
                                            row.IsRelatePO == false
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "25px",
                                                    height: "23px",
                                                    "margin-right": "30px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "/img/speed/linkNo.png",
                                                    alt: ""
                                                  }
                                                })
                                              : _vm._e()
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "BusinessNoIn",
                              label: _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                            }
                          }),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "BusinessNoOut",
                                  label: _vm.$t(
                                    "BUSINESS SERIAL NUMBER EXTERNAL"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Code",
                              label: _vm.$t("POProductCode"),
                              width: "120"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OutProductName",
                              label: _vm.$t("POProductName")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Batch", label: _vm.$t("POBATCH") }
                          }),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "Number",
                                  label: _vm.$t("Number")
                                }
                              })
                            : _vm._e(),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "UnitName",
                                  label: _vm.$t("UNIT")
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: { prop: "Color", label: _vm.$t("COLOR") }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "DestinationCountryName",
                              label: _vm.$t("MARKET")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              prop: "TrackingApproveStatus",
                              label: _vm.$t("STATUS"),
                              formatter: _vm.formatterTrackingStatus
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            trigger: "hover",
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "Statetip_" +
                                                      scope.row
                                                        .TrackingApproveStatus
                                                  )
                                                )
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatterTrackingStatus(
                                                        scope.row
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t("UPDATE TIME"),
                              prop: "LinkageUpdateDateTime",
                              formatter: _vm.formatDate
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "200px;",
                              align: "center",
                              label: _vm.$t("OPERATION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _c("div", { staticClass: "d-flex" }, [
                                        row.CopyDeclarationbox1
                                          ? _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "div",
                                                  {},
                                                  [
                                                    (row.CopySign == 2
                                                    ? false
                                                    : true)
                                                      ? _c("el-button", {
                                                          attrs: {
                                                            icon:
                                                              "el-icon-coin",
                                                            type: "text",
                                                            size: "medium",
                                                            title: _vm.$t(
                                                              "Proportion of raw materials"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.fibred(
                                                                row,
                                                                $index,
                                                                size,
                                                                type
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    (row.CopySign == 2
                                                    ? false
                                                    : row.HasProcess)
                                                      ? _c("el-button", {
                                                          attrs: {
                                                            icon:
                                                              "icon-shangchuan",
                                                            type: "text",
                                                            size: "medium",
                                                            title: _vm.$t(
                                                              "Upload"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.upload(
                                                                row,
                                                                $index,
                                                                size,
                                                                type
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _c("el-button", {
                                                      attrs: {
                                                        icon:
                                                          "el-icon-shopping-cart-full",
                                                        type: "text",
                                                        size: "medium",
                                                        title: _vm.$t(
                                                          "Relevance"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.rele(
                                                            row,
                                                            $index,
                                                            size,
                                                            type
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _c("el-button", {
                                                      attrs: {
                                                        icon:
                                                          "el-icon-edit-outline",
                                                        type: "text",
                                                        size: "medium",
                                                        title: _vm.$t("Edit")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editSOinfo(
                                                            row,
                                                            $index,
                                                            size,
                                                            type
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm.CopyDeclarationStatus(
                                                      props.row.Details.length,
                                                      row.CopySign
                                                    )
                                                      ? _c("el-button", {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "0px !important"
                                                          },
                                                          attrs: {
                                                            icon:
                                                              "el-icon-document-add",
                                                            type: "text",
                                                            size: "medium",
                                                            title: _vm.$t(
                                                              "CopyDeclaration"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.CopyDeclarationBtn(
                                                                row,
                                                                props.row
                                                                  .Details,
                                                                $index
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    row.ApproveStatus >= 401
                                                      ? _c("el-button", {
                                                          attrs: {
                                                            icon:
                                                              "el-icon-thumb",
                                                            type: "text",
                                                            size: "medium",
                                                            title: _vm.$t(
                                                              "VIEW AUDIT INFO"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.ViewAuditInfo(
                                                                row,
                                                                $index,
                                                                size,
                                                                type
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    row.ApproveStatus < 320 &&
                                                    (row.Speed == "100%" ||
                                                      row.Speed == "100.0%" ||
                                                      row.Speed == "100.00%")
                                                      ? _c("el-button", {
                                                          attrs: {
                                                            icon:
                                                              "el-icon-s-check",
                                                            type: "text",
                                                            size: "medium",
                                                            title: _vm.$t(
                                                              "Submit audit"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.SubmitAudit(
                                                                row,
                                                                $index,
                                                                size,
                                                                type
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    row.CopySign == 2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "check-cancel"
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src:
                                                                  "/img/speed/noCopy.png",
                                                                alt: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.checkCancelBtn(
                                                                    row
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          : _c("div", [
                                              row.checkBox
                                                ? _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      row.checkBoxStatus
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "check-box",
                                                              style: row.CopyDeclarationId
                                                                ? "border-color: #009eff;"
                                                                : "",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.checkBtn(
                                                                    row,
                                                                    props.row
                                                                      .Details
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("div", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: row.CopyDeclarationId
                                                                      ? true
                                                                      : false,
                                                                    expression:
                                                                      "row.CopyDeclarationId ? true : false"
                                                                  }
                                                                ]
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "operation-text preservation",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.preservationCopy(
                                                                row,
                                                                props.row
                                                                  .Details
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("Hold")
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "operation-text cancel",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.cancelCopy(
                                                                row,
                                                                props.row
                                                                  .Details
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("Cancel")
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                            ])
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "menuForm",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var type = ref.type
                    return [
                      type == "add"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-plus-outline",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowSave()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Hold")))]
                          )
                        : _vm._e(),
                      type == "edit"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-check",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowUpdate()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Hold")))]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.closeDialog()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            }),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("ChooseUploadBrand"),
                  visible: _vm.BranddialogTableVisible,
                  "modal-append-to-body": false,
                  width: "30%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.BranddialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("Brand"), "label-width": "120px" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "calc(100% - 115px)" },
                        attrs: { filterable: "", placeholder: "" },
                        model: {
                          value: _vm.brandChoose,
                          callback: function($$v) {
                            _vm.brandChoose = $$v
                          },
                          expression: "brandChoose"
                        }
                      },
                      _vm._l(_vm.brandList, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", padding: "50px 0" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.submitBrandChoose()
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Save")))]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    )
                  ],
                  1
                ),
                _c("el-form-item")
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("EditSOinformation"),
                  visible: _vm.SoDetaildialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.SoDetaildialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.SoDetailEdit,
                      rules: _vm.rules,
                      "label-position": "top",
                      "label-width": "150px"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-left": "0px",
                              "margin-right": "5px !important",
                              border: "none !important",
                              color: "#f56c6c"
                            },
                            attrs: { size: "small", plain: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("upSaleTip")))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("POProductCode"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("POProductCode") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.Code,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "Code",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.Code"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("POProductName"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("POProductName") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.OutProductName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "OutProductName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "SoDetailEdit.OutProductName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t(
                                    "BUSINESS SERIAL NUMBER INTERNAL"
                                  ),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "BUSINESS SERIAL NUMBER INTERNAL"
                                      )
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.BusinessNoIn,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "BusinessNoIn",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.BusinessNoIn"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t(
                                    "BUSINESS SERIAL NUMBER EXTERNAL"
                                  ),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "BUSINESS SERIAL NUMBER EXTERNAL"
                                      ),
                                      prop: "BusinessNoOut"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      model: {
                                        value: _vm.SoDetailEdit.BusinessNoOut,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "BusinessNoOut",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.BusinessNoOut"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("POBATCH"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("POBATCH") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.Batch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "Batch",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.Batch"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("InternalProductName"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("InternalProductName")
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          "allow-create": "",
                                          "default-first-option": "",
                                          placeholder: ""
                                        },
                                        on: { change: _vm.selectChanged },
                                        model: {
                                          value: _vm.SoDetailEdit.ProductCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.SoDetailEdit,
                                              "ProductCode",
                                              $$v
                                            )
                                          },
                                          expression: "SoDetailEdit.ProductCode"
                                        }
                                      },
                                      _vm._l(_vm.productData, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("Number") } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.SoDetailEdit.Number,
                                    callback: function($$v) {
                                      _vm.$set(_vm.SoDetailEdit, "Number", $$v)
                                    },
                                    expression: "SoDetailEdit.Number"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("UNIT") } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.SoDetailEdit.UnitName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.SoDetailEdit,
                                        "UnitName",
                                        $$v
                                      )
                                    },
                                    expression: "SoDetailEdit.UnitName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("COLOR") } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.SoDetailEdit.Color,
                                    callback: function($$v) {
                                      _vm.$set(_vm.SoDetailEdit, "Color", $$v)
                                    },
                                    expression: "SoDetailEdit.Color"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 12, sm: 24, md: 12 } })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          padding: "50px 0"
                        }
                      },
                      [
                        !_vm.isLocked
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.submitInfoSO()
                                    _vm.SoDetaildialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.SoDetaildialogTableVisible = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Cancel")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("Proportion of raw materials"),
                  visible: _vm.fibredialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "20vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.fibredialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "avue-crud",
                  {
                    ref: "crudComponent",
                    attrs: {
                      option: _vm.setDataComponent.tableOpt,
                      data: _vm.tableDataComponent,
                      "row-style": _vm.rowStyle,
                      page: _vm.pageComponent
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.pageComponent = $event
                      },
                      "current-change": _vm.currentComponentChange,
                      "row-update": _vm.handleComponentUpdate,
                      "row-del": _vm.handDel,
                      "row-save": _vm.handleSave
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "CategoryForm",
                        fn: function(ref) {
                          var type = ref.type
                          var disabled = ref.disabled
                          var scope = ref.scope
                          return [
                            _c("el-autocomplete", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "fetch-suggestions": _vm.SearchCategoryInfo
                              },
                              model: {
                                value: _vm.objComponent.Category,
                                callback: function($$v) {
                                  _vm.$set(_vm.objComponent, "Category", $$v)
                                },
                                expression: "objComponent.Category"
                              }
                            }),
                            type == "add"
                              ? _c("el-tag", [
                                  _vm._v(_vm._s(_vm.$t("Category such as")))
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "menu",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var size = ref.size
                          var type = ref.type
                          return [
                            row.ParentId != "0" && !_vm.isLocked
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-edit-outline",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("Edit")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs.crudComponent.rowEdit(
                                        row,
                                        index
                                      )
                                      _vm.isParent = false
                                    }
                                  }
                                })
                              : _vm._e(),
                            !_vm.isLocked
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-delete-solid",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("Delete")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handDel(row, index)
                                    }
                                  }
                                })
                              : _vm._e(),
                            row.ParentId == "0" && !_vm.isLocked
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-plus",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("Add Details")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs.crudComponent.rowAdd()
                                      _vm.addHeadComponent(row)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "menuForm",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var type = ref.type
                          return [
                            type == "add"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-circle-plus-outline",
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.crudComponent.rowSave()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Hold")))]
                                )
                              : _vm._e(),
                            type == "edit"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-circle-check",
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.crudComponent.rowUpdate()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Hold")))]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-circle-close",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.crudComponent.closeDialog()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.objComponent,
                      callback: function($$v) {
                        _vm.objComponent = $$v
                      },
                      expression: "objComponent"
                    }
                  },
                  [
                    _c(
                      "template",
                      {
                        staticStyle: { width: "calc(100% - 80px)" },
                        slot: "menuLeft"
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 24,
                                  sm: 23,
                                  md: 23,
                                  lg: 23,
                                  xl: 23
                                }
                              },
                              [
                                !_vm.isLocked
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          icon: "el-icon-plus"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.$refs.crudComponent.rowAdd()
                                            _vm.parentId = "0"
                                            _vm.isParent = true
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("ADD")))]
                                    )
                                  : _vm._e(),
                                _c("el-tag", { attrs: { size: "small" } }, [
                                  _vm._v(_vm._s(_vm.$t("component such as")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("Audit History"),
                  visible: _vm.RejectdialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.RejectdialogTableVisible = $event
                  }
                }
              },
              [
                !_vm.isViewAudit
                  ? _c(
                      "el-form",
                      {
                        ref: "Rejectrules",
                        attrs: {
                          model: _vm.RejectEdit,
                          rules: _vm.Rejectrules,
                          "label-position": "top",
                          "label-width": "150px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, sm: 24, md: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RESUBMIT DESCRIPTION"),
                                      prop: "Remarks",
                                      rules: _vm.Rejectrules.Remarks
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "300",
                                        "show-word-limit": "",
                                        autosize: { minRows: 4, maxRows: 6 }
                                      },
                                      model: {
                                        value: _vm.RejectEdit.Remarks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "Remarks",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.Remarks"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              padding: "20px 0"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitReject("Rejectrules")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Save")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.RejectdialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("avue-crud", {
                  attrs: {
                    option: _vm.setData.tableOptRejectList,
                    data: _vm.tableDataRejectList,
                    "row-style": _vm.rowStyle,
                    page: _vm.pageRejectList
                  },
                  on: {
                    "update:page": function($event) {
                      _vm.pageRejectList = $event
                    },
                    "current-change": _vm.currentRejectListChange
                  }
                })
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("importPOList"),
                  visible: _vm.dialogTableVisible,
                  "modal-append-to-body": false,
                  width: "90%",
                  top: "2vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "avue-crud",
                  {
                    ref: "crud",
                    attrs: {
                      option: _vm.setData.tableOptFile,
                      data: _vm.tableFileData,
                      "row-style": _vm.rowStyle
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "remarkShow",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var size = ref.size
                          var type = ref.type
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  placement: "bottom-start"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatterColumnRemark(row))
                                    )
                                  ]
                                ),
                                _c("el-link", { attrs: { type: "warning" } }, [
                                  _vm._v(_vm._s(row.remarkShow))
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("template", {
                      staticStyle: { width: "calc(100% - 80px)" },
                      slot: "menuLeft"
                    }),
                    _c("template", { slot: "tip" })
                  ],
                  2
                ),
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "formDiv" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { float: "right" },
                            attrs: { type: "primary" },
                            on: { click: _vm.submitSelectData }
                          },
                          [_vm._v(_vm._s(_vm.$t("Yes")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("IMPORT SO VIA EXCEL FILE"),
                  visible: _vm.dialogImportTableVisible,
                  "modal-append-to-body": false,
                  width: "40%",
                  top: "20vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogImportTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c("el-col", { staticClass: "formDiv" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-message-box__status el-icon-warning"
                        },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.downTemplate }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Import the sale order fill in the template,and download the template"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: {
                          float: "left",
                          display: "flex",
                          "align-items": "flex-start",
                          "justify-content": "flex-start",
                          "padding-top": "10px",
                          "padding-left": "39px !important"
                        },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 14 }
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              "on-change": _vm.beforeUpload,
                              "auto-upload": false,
                              multiple: false,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "before-remove": _vm.beforeRemove,
                              accept: ".xlsx",
                              "file-list": _vm.fileList,
                              action: _vm.targetAction
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "suffix-icon":
                                  "iconfont icon-wenjianjiaFolders5",
                                size: "medium"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.uploadClick()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: {
                          float: "left",
                          display: "flex",
                          "align-items": "flex-start",
                          "justify-content": "flex-start",
                          "padding-top": "10px",
                          "padding-left": "39px !important"
                        },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 14 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.importOrder }
                          },
                          [_vm._v(_vm._s(_vm.$t("Upload File")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("exportSO"),
                  visible: _vm.dialogExportVisible,
                  "modal-append-to-body": false,
                  width: "30%",
                  top: "20vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogExportVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c("el-col", { staticClass: "formDiv" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-message-box__status el-icon-warning"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "black",
                                "font-size": "8px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Maximum 10000 orders")))]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "formDiv" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "el-button el-button--primary el-button--small ",
                            staticStyle: {
                              float: "right",
                              "margin-left": "0px",
                              "margin-right": "5px !important"
                            },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.exportAll }
                          },
                          [_vm._v(_vm._s(_vm.$t("Export All")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "el-button el-button--primary el-button--small",
                            staticStyle: {
                              float: "right",
                              "margin-left": "0px",
                              "margin-right": "5px !important"
                            },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.exportCurrent }
                          },
                          [_vm._v(_vm._s(_vm.$t("Export Current")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }