<template>
  <div class="pvhFactory" id="salesOrd">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:10px;">{{$t('SALES ORDER')}}</div>
      </div>
      <template>
          <el-row v-if="!isAdvancedSearch" class="topFromSearch">
              <el-col class="formDiv" :xs="24" :sm="21" :md="21" :lg="21" :xl="21" style="align-self: flex-end; ">
                  <el-input style="width:550px;" v-model="SeaCodeVal" :placeholder="$t('PLEASE ENTER THE KEYWORDS TO BE SEARCHED')"></el-input>
                  <el-badge :value="selectBadge" style="margin-right: 18px; margin-left: 18px; ">
                      <el-button plain icon="iconfont shaixuan2" size="mini" :title="$t('Display Filter')" @click="isAdvancedSearch=true;">{{$t('Filter')}}</el-button>
                  </el-badge>
                  <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search"> {{$t('Search')}}</el-button>
              </el-col>
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3" style="padding-top: 20px; text-align: right; align-self: flex-end;">
                      <el-button type="primary" icon="iconfont daochu2" size="mini" :title="$t('Export')" plain @click="exportOrder"> {{$t('Export')}}</el-button>
              </el-col>
          </el-row>
          <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="SeaCodeVal==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('KEYWORDS')}}:</span>
                      <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="SeaCodeVal"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="soVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('SO')}}:</span>
                      <el-input v-model="soVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="codeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('POProductCode')}}:</span>
                      <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span id="venSpan" :style="fromVal.ids.length == 0?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('FROM')}}:</span>
                      <el-select v-model="fromVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang">
                          <el-option v-for="item in this.fromData"
                                     :key="item.value"
                                     :label="item.label"
                                     :value="item.value">
                          </el-option>
                      </el-select>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4" v-if="!isCanAgent">
                      <span id="otherStatusSpan" :style="otherStatusVal ==null||otherStatusVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('STATE')}}:</span>
                      <el-select v-model="otherStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="otherStatuschang" clearable>
                          <el-option v-for="item in this.traingStatusData"
                                     :key="item.value"
                                     :label="item.label"
                                     :value="item.value">
                          </el-option>
                      </el-select>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4" v-if="isCanAgent">
                      <span id="traingStatusSpan" :style="traingStatusVal ==null||traingStatusVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('STATE')}}:</span>
                      <el-select v-model="traingStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="traingStatuschang" clearable>
                          <el-option v-for="item in this.traingStatusData"
                                     :key="item.value"
                                     :label="item.label"
                                     :value="item.value">
                          </el-option>
                      </el-select>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="businessnoinVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('BUSINESS SERIAL NUMBER INTERNAL')}}:</span>
                      <el-input v-model="businessnoinVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="businessnooutVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}}:</span>
                      <el-input v-model="businessnooutVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="batchVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('POBATCH')}}:</span>
                      <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>

                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="beginTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('ORDERBEINGTIME')}}:</span>
                      <el-date-picker class="dataicon" v-model.trim="beginTimeVal"
                                      type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                      </el-date-picker>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="endTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('ORDERENDTIME')}}:</span>
                      <el-date-picker class="dataicon" v-model.trim="endTimeVal"
                                      type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                      </el-date-picker>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="UTbeginTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('UPDATE START DATE')}}:</span>
                      <el-date-picker class="dataicon" v-model.trim="UTbeginTimeVal"
                                      type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                      </el-date-picker>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="UTendTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('UPDATE END DATE')}}:</span>
                      <el-date-picker class="dataicon" v-model.trim="UTendTimeVal"
                                      type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                      </el-date-picker>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="colorVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('COLOR')}}:</span>
                      <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
              </el-col>
 
          </el-row>
          <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right; align-self: flex-end;">
                      <el-button plain icon="iconfont shaixuan1" size="mini"  :title="$t('Hidden Filter')" @click="isAdvancedSearch=false;getSelectBadge()">  {{$t('Hidden Filter')}} </el-button>
                      <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini"  type="primary" @click="search">  {{$t('Search')}}</el-button>
                      <el-button type="primary" icon="iconfont daochu2" size="mini"  :title="$t('Export')" plain @click="exportOrder">{{$t('Export')}}</el-button>
              </el-col>
          </el-row>

              <avue-crud class="pvhTable"
                         v-loading="loading"
                         ref="crud" v-model="obj"
                         :option="setData.tableOpt"
                         :data="tableData"
                         :row-style="rowStyle"
                         :cell-style="cellStyle"
                         :page.sync="page"
                         @selection-change="selectionChange"
                         @current-change="currentChange"
                         @row-update="handleUpdate"
                         @sort-change="sortChange">
                  <template slot="SpeedHeader" slot-scope="{ column }">
                      <label>{{ column.label }}</label>
                      <el-tooltip class="item" effect="dark" placement="bottom">
                          <div slot="content">
                              {{ $t('saleTip') }}
                          </div>
                          <i class="el-icon-warning"
                             style="margin-left: 3px; font-size: 14px"></i>
                      </el-tooltip>
                  </template>
                  <template slot-scope="{row,index,size,type}" slot="Speed">
                      <el-tooltip class="item" effect="light" placement="bottom-start">
                          <div slot="content">
                              <span style="font-weight: 500; color: #000; font-size: 14px;">{{$t('To do list for this order')}}:</span>
                              <div v-for="(item,index) in row.PendingSummaryAlls.CurrentSummaryInfos" v-bind:key="index">
                                  <span>{{$t('No.')}}{{ item.Index }}({{ item.BusinessNoIn }})：{{$t('to do a total')}}</span>
                                  <span style="font-weight: 600; color: #ff6a00; font-size: 12px; margin-left: 5px;">{{item.Count}}</span>
                              </div>
                              <span style="font-weight: 500; color: #000; font-size: 14px;">{{$t('To do list for Associate purchasing')}}:</span>
                              <div v-for="(item,index) in row.PendingSummaryAlls.OtherSummaryInfos" v-bind:key="index">
                                  <span>{{$t('No.')}}{{ item.Index }}({{ item.BusinessNoIn }})：{{$t('to do a total')}}</span>
                                  <span style="font-weight: 600; color: #ff6a00; font-size: 12px; margin-left: 5px;">{{item.Count}}</span>
                              </div>
                          </div>

                          <el-link type="warning">{{row.Speed}}</el-link>
                      </el-tooltip>
                  </template>
                  <template slot-scope="{row,index,size,type}" slot="TrackingApproveStatus">
                      <el-popover trigger="hover" placement="top">
                          <p> {{$t('Statetip_'+row.TrackingApproveStatus) }}</p>
                          <div slot="reference">
                              <span style="margin-left: 10px">{{ formatterColumn(row) }}</span>
                          </div>
                      </el-popover>
                  </template>
                  <template slot-scope="{row,index,size,type}" slot="menu">
                      <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" v-if="row.ApproveStatus<650&&row.LockedStatus!=605" @click="$refs.crud.rowEdit(row,index)"></el-button>
                      <el-button icon="el-icon-s-order" type="text" size="medium" :title="$t('Look Over')" v-if="row.ApproveStatus>=650||row.LockedStatus==605" @click="$refs.crud.rowView(row,index)"></el-button>
                      <!--<el-button icon="el-icon-edit" type="text" size="medium" :title="$t('EDIT AGENT PO')" v-if="isCanAgent" @click="editPur(row)"></el-button>-->
                      <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" v-if="isCanAgent&&row.ApproveStatus<650&&row.LockedStatus!=605" @click="delPur(row)"></el-button>
                  </template>
                  <template slot="DestinationCountryHeader" slot-scope="{ column }">
                      <label>{{ column.label }}</label>
                      <el-tooltip class="item" effect="dark" placement="bottom">
                          <div slot="content">
                              {{ $t('MARKET EXPLAIN') }}
                          </div>
                          <i class="el-icon-warning"
                             style="margin-left: 3px; font-size: 14px"></i>
                      </el-tooltip>
                  </template>
                  <template slot="expand" slot-scope="props">
                      <el-table :data="props.row.Details" style="width: 100%" :header-cell-style="tableHeaderColor" :row-class-name="tableRowClassName">
                          <el-table-column type="index"></el-table-column>
                          <el-table-column prop="Speed" align="center" :label="$t('DEGREE OF COMPLETION')">
                              <template slot-scope="{row,index,size,type}">
                                  <el-tooltip class="item" effect="light" placement="bottom-start">
                                      <div slot="content">
                                          <div style="display: flex; flex-direction: column;">
                                              <span v-if="row.ProcessSummarys.length==0" style="font-weight: 500; color: #000; font-size: 14px;">{{$t('To do list for this order')}}:{{$t('nothing')}} </span>
                                              <span v-if="row.ProcessSummarys.length!=0" style="font-weight: 500; color: #000; font-size: 14px;">{{$t('To do list for this order')}}:</span>
                                              <div v-for="(item,index) in row.ProcessSummarys" v-bind:key="index">
                                                  <span style="font-weight: 600; color: #000; font-size: 12px; margin-left: 5px;">{{ formatColumn(item.Type) }}</span>
                                                  <span v-if="item.ProcessName!=null" style="font-weight: 600; color: #000; font-size: 12px; margin-left: 5px; margin-right: 5px;">:{{item.ProcessName}}</span>
                                                  <el-button type="text" v-if="item.Type=='NoUpinfo'" @click="upload(row,$index,size,type)" style="font-weight: 600;  font-size: 10px; margin-left: 5px; margin-right: 5px;">{{$t('Click to fill in')}}</el-button>
                                                  <el-button type="text" v-if="item.Type=='NoWriteComponent'" @click="fibred(row,$index,size,type)" style="font-weight: 600;  font-size: 10px; margin-left: 5px; margin-right: 5px;">{{$t('Click to fill in')}}</el-button>
                                                  <el-button type="text" v-if="item.Type=='NoRelation'" @click="rele(row,$index,size,type)" style="font-weight: 600;  font-size: 10px; margin-left: 5px; margin-right: 5px;">{{$t('Click to fill in')}}</el-button>
                                              </div>
                                              <span v-if="row.DownPoProcessSummarys.length==0" style="font-weight: 500; color: #000; font-size: 14px;">{{$t('To do list for Associate purchasing')}}:{{$t('nothing')}} </span>
                                              <span v-if="row.DownPoProcessSummarys.length!=0" style="font-weight: 500; color: #000; font-size: 14px;">{{$t('To do list for Associate purchasing')}}:</span>
                                              <div v-for="(item,index) in row.DownPoProcessSummarys" v-bind:key="index">
                                                  <span style="font-weight: 600; color: #000; font-size: 12px; margin-left: 5px;">{{item.Type}}</span>
                                                  <span v-if="item.ProcessName!=''" style="font-weight: 600; color: #000; font-size: 12px; margin-left: 5px; margin-right: 5px;">:{{item.ProcessName}}</span>
                                              </div>

                                          </div>
                                      </div>
                                      <el-link type="warning" v-if="row.IsRelatePO">{{row.Speed}}</el-link>
                                  </el-tooltip>
                                  <el-tooltip class="item" effect="light" placement="bottom-start">
                                      <div slot="content">
                                          <div style="display: flex; flex-direction: column;">
                                              <span v-if="row.IsRelatePO == false" style="font-weight: 500; color: #000; font-size: 14px;">{{$t('SO not relate PO')}}</span>
                                          </div>
                                      </div>
                                      <div class="icon">
                                          <img style="width: 25px;height: 23px;margin-right: 30px;" v-if="row.IsRelatePO == false"
                                               src="/img/speed/linkNo.png"
                                               alt="" />
                                      </div>
                                  </el-tooltip>
                              </template>
                          </el-table-column>
                          <el-table-column prop="BusinessNoIn" :label="$t('BUSINESS SERIAL NUMBER INTERNAL')"></el-table-column>
                          <el-table-column v-if="showOut" prop="BusinessNoOut" :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')"></el-table-column>
                          <el-table-column prop="Code" :label="$t('POProductCode')" width="120"></el-table-column>
                          <el-table-column prop="OutProductName" :label="$t('POProductName')"></el-table-column>
                          <el-table-column prop="Batch" :label="$t('POBATCH')"></el-table-column>
                          <el-table-column v-if="showOut" prop="Number" :label="$t('Number')"></el-table-column>
                          <el-table-column v-if="showOut" prop="UnitName" :label="$t('UNIT')"></el-table-column>
                          <el-table-column prop="Color" :label="$t('COLOR')"></el-table-column>
                          <el-table-column prop="DestinationCountryName" :label="$t('MARKET')">
                          </el-table-column>
                          <el-table-column align="center" prop="TrackingApproveStatus" :label="$t('STATUS')" :formatter="formatterTrackingStatus">
                              <template slot-scope="scope">
                                  <el-popover trigger="hover" placement="top">
                                      <p> {{$t('Statetip_'+scope.row.TrackingApproveStatus) }}</p>
                                      <div slot="reference">
                                          <span style="margin-left: 10px">{{ formatterTrackingStatus(scope.row) }}</span>
                                      </div>
                                  </el-popover>
                              </template>
                          </el-table-column>
                          <el-table-column align="center" :label="$t('UPDATE TIME')" prop="LinkageUpdateDateTime" :formatter="formatDate"> </el-table-column>
                          <el-table-column width="200px;" align="center" :label="$t('OPERATION')">
                              <template slot-scope="{row,$index,size,type}">
                                  <!--                                  - {{row.CopySign}} - {{row.CopyDeclarationId ? (row.CopyDeclarationId.slice(row.CopyDeclarationId.length-2, row.CopyDeclarationId.length)) : 'no'}} - -->
                                  <div class="d-flex">
                                      <div class="d-flex" v-if="row.CopyDeclarationbox1">
                                          <div class="">
                                              <el-button icon="el-icon-coin" type="text" size="medium" :title="$t('Proportion of raw materials')" v-if="row.CopySign == 2 ? false : true" @click="fibred(row,$index,size,type)"></el-button>
                                              <el-button icon="icon-shangchuan" type="text" size="medium" :title="$t('Upload')" v-if="row.CopySign == 2 ? false : row.HasProcess" @click="upload(row,$index,size,type)"></el-button>
                                              <el-button icon="el-icon-shopping-cart-full" type="text" size="medium" :title="$t('Relevance')" @click="rele(row,$index,size,type)"></el-button>
                                              <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="editSOinfo(row,$index,size,type)"></el-button>
                                              <el-button icon="el-icon-document-add" style="margin-left: 0px !important;" type="text" size="medium" :title="$t('CopyDeclaration')" v-if="CopyDeclarationStatus(props.row.Details.length, row.CopySign)" @click="CopyDeclarationBtn(row, props.row.Details, $index)"></el-button>
                                              <el-button icon="el-icon-thumb" type="text" size="medium" :title="$t('VIEW AUDIT INFO')" v-if="row.ApproveStatus>=401" @click="ViewAuditInfo(row,$index,size,type)"></el-button>
                                              <el-button icon="el-icon-s-check" type="text" size="medium" :title="$t('Submit audit')" v-if="row.ApproveStatus<320&&(row.Speed=='100%'||row.Speed=='100.0%'||row.Speed=='100.00%')" @click="SubmitAudit(row,$index,size,type)"></el-button>
                                              <!--<el-button icon="icon-dingwei1" type="text" size="medium" :title="$t('Tracing')" v-if="isCanAgent" @click="tracingPur(row)"></el-button>-->
                                          </div>
                                          <div class="d-flex">
                                              <div class="check-cancel" v-if="row.CopySign == 2">
                                                  <img src="/img/speed/noCopy.png" alt=""
                                                       @click="checkCancelBtn(row)">
                                              </div>
                                          </div>
                                      </div>
                                      <div v-else>
                                          <div class="d-flex" v-if="row.checkBox">
                                              <div class="check-box" :style="row.CopyDeclarationId ? 'border-color: #009eff;' : ''" v-if="row.checkBoxStatus" @click="checkBtn(row, props.row.Details)"><div v-show="row.CopyDeclarationId ? true : false"></div></div>
                                          </div>
                                          <div class="d-flex" v-else>
                                              <p class="operation-text preservation" @click="preservationCopy(row, props.row.Details)">{{$t("Hold")}}</p>
                                              <p class="operation-text cancel" @click="cancelCopy(row, props.row.Details)">{{$t("Cancel")}}</p>
                                          </div>
                                      </div>
                                  </div>
                              </template>
                          </el-table-column>
                      </el-table>
                  </template>
                  <template slot-scope="{row,index,type}" slot="menuForm">
                      <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                      <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                      <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                  </template>
              </avue-crud>
              <el-dialog :title="$t('ChooseUploadBrand')" :visible.sync="BranddialogTableVisible" :modal-append-to-body="false" width="30%" top="1vh">
                  <el-form-item :label="$t('Brand')" label-width="120px">
                      <el-select v-model="brandChoose" filterable placeholder="" style="width: calc(100% - 115px);">
                          <el-option v-for="item in brandList"
                                     :key="item.value"
                                     :label="item.label"
                                     :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
                  <div style="text-align:center;padding:50px 0;">
                      <el-button type="primary" @click="submitBrandChoose();BranddialogTableVisible = false;">{{$t('Save')}}</el-button>
                      <el-button @click="BranddialogTableVisible = false">{{$t('Cancel')}}</el-button>
                  </div>
                  <el-form-item>

                  </el-form-item>
              </el-dialog>
              <el-dialog :title="$t('EditSOinformation')" :visible.sync="SoDetaildialogTableVisible" :modal-append-to-body="false" width="70%" top="1vh">
                  <el-form :model="SoDetailEdit" :rules="rules" label-position="top" label-width="150px">
                      <div>
                          <el-button size="small" style="margin-left: 0px; margin-right: 5px !important; border: none !important; color: #f56c6c;" plain>{{$t('upSaleTip')}}</el-button>
                      </div>
                      <el-row>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-tooltip class="item" effect="dark" :content="$t('POProductCode')" placement="top-start">
                                  <el-form-item :label="$t('POProductCode')">
                                      <el-input v-model="SoDetailEdit.Code" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                  </el-form-item>
                              </el-tooltip>
                          </el-col>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-tooltip class="item" effect="dark" :content="$t('POProductName')" placement="top-start">
                                  <el-form-item :label="$t('POProductName')">
                                      <el-input v-model="SoDetailEdit.OutProductName" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                  </el-form-item>
                              </el-tooltip>

                          </el-col>
                      </el-row>
                      <el-row>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-tooltip class="item" effect="dark" :content="$t('BUSINESS SERIAL NUMBER INTERNAL')" placement="top-start">
                                  <el-form-item :label="$t('BUSINESS SERIAL NUMBER INTERNAL')">
                                      <el-input v-model="SoDetailEdit.BusinessNoIn" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                  </el-form-item>
                              </el-tooltip>
                          </el-col>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-tooltip class="item" effect="dark" :content="$t('BUSINESS SERIAL NUMBER EXTERNAL')" placement="top-start">
                                  <el-form-item :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')" prop="BusinessNoOut">
                                      <el-input v-model="SoDetailEdit.BusinessNoOut" style="width: calc(100% - 115px);"></el-input>
                                  </el-form-item>
                              </el-tooltip>

                          </el-col>
                      </el-row>
                      <el-row>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-tooltip class="item" effect="dark" :content="$t('POBATCH')" placement="top-start">
                                  <el-form-item :label="$t('POBATCH')">
                                      <el-input v-model="SoDetailEdit.Batch" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                  </el-form-item>
                              </el-tooltip>

                          </el-col>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-tooltip class="item" effect="dark" :content="$t('InternalProductName')" placement="top-start">
                                  <el-form-item :label="$t('InternalProductName')">
                                      <el-select v-model="SoDetailEdit.ProductCode" filterable allow-create default-first-option style="width: calc(100% - 115px);" placeholder="" @change="selectChanged">
                                          <el-option v-for="item in productData"
                                                     :key="item.value"
                                                     :label="item.label"
                                                     :value="item.value">
                                          </el-option>
                                      </el-select>

                                  </el-form-item>
                              </el-tooltip>
                          </el-col>
                      </el-row>
                      <el-row>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-form-item :label="$t('Number')">
                                  <el-input-number v-model="SoDetailEdit.Number" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input-number>
                              </el-form-item>
                          </el-col>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-form-item :label="$t('UNIT')">
                                  <el-input v-model="SoDetailEdit.UnitName" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                              </el-form-item>
                          </el-col>
                      </el-row>
                      <el-row>
                          <el-col :span="12" :sm="24" :md="12">
                              <el-form-item :label="$t('COLOR')">
                                  <el-input v-model="SoDetailEdit.Color" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="12" :sm="24" :md="12">

                          </el-col>
                      </el-row>
                      <div style="text-align:center;padding:50px 0;">
                          <el-button type="primary" v-if="!isLocked" @click="submitInfoSO();SoDetaildialogTableVisible = false;">{{$t('Yes')}}</el-button>
                          <el-button @click="SoDetaildialogTableVisible = false">{{$t('Cancel')}}</el-button>
                      </div>
                  </el-form>
              </el-dialog>
              <el-dialog :title="$t('Proportion of raw materials')" :visible.sync="fibredialogTableVisible" :modal-append-to-body="false" width="70%" top="20vh">
                  <avue-crud ref="crudComponent" v-model="objComponent" :option="setDataComponent.tableOpt" :data="tableDataComponent" :row-style="rowStyle" :page.sync="pageComponent" @current-change="currentComponentChange" @row-update="handleComponentUpdate" @row-del="handDel" @row-save="handleSave">
                      <template slot="CategoryForm" scope="{type,disabled,scope}">
                          <el-autocomplete v-model="objComponent.Category"
                                           :fetch-suggestions="SearchCategoryInfo" style="width:100%;"></el-autocomplete>
                          <el-tag v-if="type=='add'">{{$t('Category such as')}}</el-tag>
                      </template>
                      <template slot="menuLeft" style="width: calc(100% - 80px);">
                          <el-row :gutter="20">
                              <el-col :xs="24" :sm="23" :md="23" :lg="23" :xl="23">
                                  <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;" v-if="!isLocked" @click="$refs.crudComponent.rowAdd();parentId='0';isParent=true;">{{$t('ADD')}}</el-button>
                                  <el-tag size="small">{{$t('component such as')}}</el-tag>
                              </el-col>
                          </el-row>
                      </template>

                      <template slot-scope="{row,index,size,type}" slot="menu">
                          <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" v-if="row.ParentId!='0'&&!isLocked" @click="$refs.crudComponent.rowEdit(row,index);isParent=false;"></el-button>
                          <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" v-if="!isLocked" @click="handDel(row,index)"></el-button>
                          <el-button icon="el-icon-plus" type="text" size="medium" :title="$t('Add Details')" v-if="row.ParentId=='0'&&!isLocked" @click="$refs.crudComponent.rowAdd();addHeadComponent(row)"></el-button>
                      </template>
                      <template slot-scope="{row,index,type}" slot="menuForm">
                          <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crudComponent.rowSave();">{{$t("Hold")}}</el-button>
                          <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crudComponent.rowUpdate()">{{$t("Hold")}}</el-button>
                          <el-button icon="el-icon-circle-close" size="small" @click="$refs.crudComponent.closeDialog()">{{$t("Cancel")}}</el-button>
                      </template>
                  </avue-crud>
              </el-dialog>
              <el-dialog :title="$t('Audit History')" :visible.sync="RejectdialogTableVisible" :modal-append-to-body="false" width="70%" top="1vh">
                  <el-form :model="RejectEdit" :rules="Rejectrules" ref="Rejectrules" label-position="top" label-width="150px" v-if="!isViewAudit">
                      <el-row>
                          <el-col :span="24" :sm="24" :md="24">
                              <el-form-item :label="$t('RESUBMIT DESCRIPTION')" prop="Remarks" :rules="Rejectrules.Remarks">
                                  <el-input v-model="RejectEdit.Remarks" style="width: calc(100% - 115px);" type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 4, maxRows:6}"></el-input>
                              </el-form-item>
                          </el-col>
                      </el-row>
                      <div style="text-align:center;padding:20px 0;">
                          <el-button type="primary" @click="submitReject('Rejectrules');">{{$t('Save')}}</el-button>
                          <el-button @click="RejectdialogTableVisible = false">{{$t('Cancel')}}</el-button>
                      </div>
                  </el-form>
                  <avue-crud :option="setData.tableOptRejectList" :data="tableDataRejectList" :row-style="rowStyle" :page.sync="pageRejectList" @current-change="currentRejectListChange">
                  </avue-crud>
              </el-dialog>
              <el-dialog :title="$t('importPOList')" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh">
                  <avue-crud ref="crud" :option="setData.tableOptFile" :data="tableFileData" :row-style="rowStyle">
                      <template slot-scope="{row,index,size,type}" slot="remarkShow">
                          <el-tooltip class="item" effect="light" placement="bottom-start">
                              <div slot="content">{{formatterColumnRemark(row)}}</div>
                              <el-link type="warning">{{row.remarkShow}}</el-link>
                          </el-tooltip>
                      </template>
                      <template slot="menuLeft" style="width: calc(100% - 80px);">
                      </template>
                      <template slot="tip">
                      </template>
                  </avue-crud>
                  <el-row :gutter="0" class="topFromSearch">
                      <el-col class="formDiv">
                          <el-button type="primary" style="float:right" @click="submitSelectData">{{$t('Yes')}}</el-button>
                      </el-col>
                  </el-row>
              </el-dialog>
              <el-dialog :title="$t('IMPORT SO VIA EXCEL FILE')" :visible.sync="dialogImportTableVisible" :modal-append-to-body="false" width="40%" top="20vh">

                  <el-row :gutter="0" class="topFromSearch">
                      <el-col class="formDiv">
                          <div class="el-message-box__status el-icon-warning">
                              <el-link type="primary" @click="downTemplate">{{$t('Import the sale order fill in the template,and download the template')}}</el-link>
                              <!--<span style="color:black;font-size:8px"></span>-->
                              <!--<el-link type="warning" >{{Templeate}}</el-link>-->
                              <!--<el-button type="primary" size="small" style="margin-left: 0px !important" plain >{{$t('Upload File')}}</el-button>-->
                          </div>
                      </el-col>
                  </el-row>
                  <el-row :gutter="0">
                      <el-col class="formDiv" :xs="24" :sm="24" :md="24" :lg="24" :xl="14" style=" float: left; display: flex; align-items: flex-start; justify-content: flex-start; padding-top: 10px; padding-left: 39px !important;">
                          <el-upload class="upload-demo"
                                     :on-change="beforeUpload"
                                     :auto-upload="false"
                                     :multiple="false"
                                     :on-preview="handlePreview"
                                     :on-remove="handleRemove"
                                     :before-remove="beforeRemove"
                                     accept=".xlsx"
                                     :file-list="fileList"
                                     :action="targetAction">
                              <el-input suffix-icon="iconfont icon-wenjianjiaFolders5" style="width:100%;" size="medium" @click.native="uploadClick()">
                              </el-input>
                          </el-upload>
                      </el-col>

                  </el-row>
                  <el-row :gutter="0">
                      <el-col class="formDiv" :xs="24" :sm="24" :md="24" :lg="24" :xl="14" style=" float: left; display: flex; align-items: flex-start; justify-content: flex-start; padding-top: 10px; padding-left: 39px !important;">
                          <el-button type="primary" size="small" icon="el-icon-plus" @click="importOrder">{{$t('Upload File')}}</el-button>
                      </el-col>
                  </el-row>
              </el-dialog>
              <el-dialog :title="$t('exportSO')" :visible.sync="dialogExportVisible" :modal-append-to-body="false" width="30%" top="20vh">
                  <el-row :gutter="0" class="topFromSearch">
                      <el-col class="formDiv">
                          <div class="el-message-box__status el-icon-warning">
                              <span style="color:black;font-size:8px">{{$t('Maximum 10000 orders')}}</span>
                          </div>
                      </el-col>
                  </el-row>
                  <el-row :gutter="0" class="topFromSearch">
                      <el-col class="formDiv">
                          <el-button type="primary" size="small" class="el-button el-button--primary el-button--small " style="float:right; margin-left: 0px; margin-right: 5px !important;" @click="exportAll">{{$t('Export All')}}</el-button>
                          <el-button type="primary" size="small" class="el-button el-button--primary el-button--small" style="float:right; margin-left: 0px; margin-right: 5px !important;" @click="exportCurrent">{{$t('Export Current')}}</el-button>
                      </el-col>
                  </el-row>
              </el-dialog>
      </template>
    </basic-container>
  </div>
</template>
<style>
.el-dialog__wrapper {
  transition-duration: 0.3s;
}

.othChe .el-checkbox__label {
  color: #409eff !important;
}

.el-table tr .el-table__expanded-cell {
  padding: 0 0 0 60px !important;
}

.dataicon i {
  display: none;
}

.el-icon-arrow-right:before {
  content: "\E6E0";
  font-weight: bold;
  color: #000;
  font-size: 15px;
}

.d-flex {
  display: flex;
}

.operation-text {
  cursor: pointer;
}

.el-icon-document-add {
  margin-left: 10px;
}

.preservation {
  margin-right: 10px;
  color: #2aa6ff;
}

.cancel {
  color: red;
}

.check-box {
  width: 14px;
  height: 14px;
  border: 1px solid #A9A9A9;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.check-box div {
  width: 10px;
  height: 10px;
  background: #009eff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.check-cancel {
  width: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left: 10px;
  cursor: pointer;
}

.check-cancel img {
  width: 100%;
}
</style>
<script>
import { saleOrderList, saleOrderListExcel, editSale, editDetailSO, editHeadSO, PutSaleOrderDetail_Copy, PutSaleOrderDetail_CancelCopy, SoSubmitAudit, ReducingStateSubmitAudit } from "@/api/salesOrd";
import { delHead, SoListByPoId } from "@/api/purchaseOrd";
import { ProcessDropDownList,TracingDropDownList,TracingUnifiedIsBool, ProcessAddProduct, Getguid, corpinfo,  TracingStringList } from "@/api/unified";
import { List, add, edit, del } from "@/api/saleComponent";
import { ListApproves, addResubmitsSo } from "@/api/orderApprove";
import fileDownload from 'js-file-download';
import { pvhApiBase } from '@/config/env'
import { getToken, getGrop } from '@/util/auth'

export default {
  data() {
    var valiBusinessNoOut = (rule, value, callback) => {
      if (value == '' || value == undefined || value == null) {
        callback();
      } else {
        var Detailid = this.SoDetailEdit.SoDetailId;
        TracingUnifiedIsBool("SaleOrderDetail_BusinessNoOutExist", Detailid, value).then(res => {
          var IsExist = res.data;
          if (!IsExist) {
            callback(new Error(this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('Exist')));
          } else {
            callback();
          }
        }).catch((erro) => {
          console.log(erro)
        });
      }
    };
        return {
            SeaCodeVal: '',
            selectBadge: null,
            isAdvancedSearch: false,
                fileList: [],
                dialogImportTableVisible: false,
                dialogTableVisible: false,
      loading: true,
      showOut: false,
      obj: {},
      downUrl: '',
      sortVal: "",
      isCanAgent: false, corpId: '',
      BranddialogTableVisible: false, SoDetaildialogTableVisible: false, fibredialogTableVisible: false,
      RejectdialogTableVisible: false, tableDataRejectList: [], RejectCategoryData: [], isViewAudit: false,
      dialogExportVisible: false,
      RejectEdit: {
        DetailOrderId: '',
        Remarks: '',
      },
      Rejectrules: {
        Remarks: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
      },
      SoDetailEdit: {
        SoId: "",
        SoDetailId: "",
        Code: "",
        OutProductName: "",
        OutProductCode: "",
        Batch: "",
        ProductName: "",
        ProductCode: "",
        Number: 0,
        UnitName: "",
        Color: "",
        Ingredient: "",
        Remark: "",
        BusinessNoIn: "",
        BusinessNoOut: "",
      },
      rules: {
        BusinessNoOut: [{ validator: valiBusinessNoOut, trigger: 'blur' }],
      },
      productData: [],//产品
      soVal: "",
      fromVal: {
        ids: [],
      },
      otherStatusVal: null,
      traingStatusVal: null,
            traingStatusData: [
        {
            label: this.$t('Ordering'),
          value: 150
        },
        {
            label: this.$t('Under Declaration'),
          value: 160
        },
          {
              label: this.$t('Submitted'),
              value: 320
          },
          {
              label: this.$t('Rejected'),
              value: 410
          },
          {
              label: this.$t('Resubmitted'),
              value: 550
          },
          {
              label: this.$t('Locked'),
              value: 605
          },
          {
              label: this.$t('Approved'),
              value: 660
          },
          {
              label: this.$t('Reviewed'),
              value: 670
          }
      ],
      ExportAllVal: false,
      businessnoinVal: "",
      businessnooutVal: "",
      codeVal: "",
      colorVal: "",
      batchVal: "",
      comVal: "",
      beginTimeVal: "",
      endTimeVal: "",
      UTbeginTimeVal: "",
      UTendTimeVal: "",
      checked: true,
      isLocked: false,
      tableData: [],
                tableFileData: [],
                targetAction: pvhApiBase + "Tracing/GetAgentFileOrderList",
      sectionData: [],
      fromData: [],//采购商
      BrandData: [],//采购商
      seasonData: [],//季度
      destinationCountryData: [],//销售目的国
      CategoryData: [],
      affCorpCollectionData: [],//关联合同签署公司
      affCorpWorkingData: [],//关联加工工厂
      brandList: [],
      brandChoose: "",
      soDetailId: "",
      ApproveStatus:0,
      vendorSectionId: "",
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      parentId: "0",
      isParent: true,
      rawFiberData: [],
      tableDataComponent: [],
      objComponent: {},
      pageComponent: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      pageRejectList: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
        },
        RejectTypeData: [
            {
                label: this.$t('Missing Attachments'),
                value: "Missing Attachments"
            },
            {
                label: this.$t('Low Resolution In Attachments'),
                value: "Low Resolution In Attachments"
            },
            {
                label: this.$t('Non-Requested Files Are Uploaded'),
                value: "Non-Requested Files Are Uploaded"
            },
            {
                label: this.$t('Filled In The Wrong Content'),
                value: "Filled In The Wrong Content"
            },

            {
                label: this.$t('Incomplete Content'),
                value: "Incomplete Content"
            },
            {
                label: this.$t('Other'),
                value: "Other"
            }],
        RejectStatusData: [
            {
                label: this.$t('Rejected'),
                value: 0
            },
            {
                label: this.$t('Rejected'),
                value: 410
            },
            {
                label: this.$t('RECALL'),
                value: 420
            },
            {
                label: this.$t('Resubmitted'),
                value: 550
            },
            {
                label: this.$t('Locked'),
                value: 605
            },
            {
                label: this.$t('Approved'),
                value: 660
            },
            {
                label: this.$t('Reviewed'),
                value: 670
            }],
    }
  },
  computed: {
    setData() {
          return {
              tableOptRejectList: {
                  page: false,
                  refreshBtn: false,
                  columnBtn: false,
                  simplePage: true,
                  addBtn: false,
                  addRowBtn: false,
                  editBtn: false,
                  delBtn: false,
                  cellBtn: false,
                  index: true,
                  menu: false,
                  indexLabel: ' ',
                  border: true,
                  align: "center",
                  emptyText: this.$t('No Data'),
                  tip: false,
                  column: [
                      {
                          label: this.$t('DATE'),
                          prop: 'CreateTime',
                          type: "date",
                          format: 'yyyy-MM-dd',
                          valueFormat: 'yyyy-MM-dd',
                      },
                      {
                          label: this.$t('STATE'),
                          prop: 'Status',
                          placeholder: ' ',
                          type: "select",
                          dicData: this.traingStatusData,
                      },
                      {
                          label: this.$t('ORDERS COMPANY'),
                          prop: 'ApprovedObject',
                      },
                      {
                          label: this.$t('REJECTION CATEGORY'),
                          prop: 'RejectCategory',
                          placeholder: ' ',
                          type: "select",
                          dicData: this.RejectTypeData,
                      },
                      {
                          label: this.$t('REJECTION DESCRIPTION'),
                          prop: 'RejectRemark',
                      },
                      {
                          label: this.$t('RESUBMISSION INSTRUCTIONS'),
                          prop: 'Remarks',
                      },
                  ]
              },
        tableOpt: {
          page: true,
          addBtn: false,
          refreshBtn: false,
          emptyText: this.$t('No Data'),
          menu: true,
          menuTitle: this.$t('OPERATION'),
          menuWidth: 200,
          editBtn: false,
          editBtnText: this.$t('Edit'),
          editTitle: this.$t('Edit'),
          viewTitle: this.$t('View'),
          labelPosition: 'top',
          delBtn: false,
          align: 'center',
          header: false,
          labelWidth: "33%",
          expand: true,
          rowKey: 'Id',
          expandRowKeys: [],
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          column: [
            {
              label: this.$t('DEGREE OF COMPLETION'),
              prop: 'Speed',
              editDisabled: true,
              headerslot: true,
              slot: true

            },
            {
              label: this.$t('OUT PURCHASE ORDER NO'),
              prop: 'PurchaserPo',
              placeholder: ' ',
              labelTip: this.$t('OUT PURCHASE ORDER NO'),
              formslot: true,
              filterable: true,
              editDisabled: true
            },
            {
              label: this.$t('SO'),
              prop: 'So',
              placeholder: ' ',
            },
            {
              label: this.$t('VendorSection'),
              prop: 'VendorSectionId',
              type: "select",
              filterable: true,
              dicData: this.sectionData,
              placeholder: ' ',
              editDisabled: true
            },
            {
              label: this.$t('FROM'),
              prop: 'PurchaseCorporationId',
              placeholder: ' ',
              type: "select",
              filterable: true,
              dicData: this.fromData,
              editDisabled: true

            },
            {
              label: this.$t('MARKET'),
              prop: 'DestinationCountry',
              placeholder: ' ',
              type: "select",
              filterable: true,
              multiple: true,
              headerslot: true,
              dicData: this.destinationCountryData,
              editDisabled: true
            },
            {
              label: this.$t('CONTRACT SIGNING COMPANY'),
              prop: 'AffiliatedCorpCollection',
              type: "select",
              placeholder: ' ',
              hide: true,
              filterable: true,
              dicData: this.affCorpCollectionData,
              rules: [{
                required: true,
                message: this.$t('ISNULL'),
                trigger: "blur"
              }]

            },
            {
              label: this.$t('ASSOCIATED PROCESSING PLANT'),
              prop: 'AffiliatedCorpWorking',
              type: "select",
              placeholder: ' ',
              hide: true,
              filterable: true,
              dicData: this.affCorpWorkingData,
              rules: [{
                required: true,
                message: this.$t('ISNULL'),
                trigger: "blur"
              }]

            },
            {
              label: this.$t('POBATCH'),
              prop: 'Batch',
              hide: true,
              placeholder: ' ',
              editDisabled: true
            },
            {
              label: this.$t('Brand'),
              prop: 'Brand',
              placeholder: ' ',
              type: "select",
              filterable: true,
              dicData: this.BrandData,
              editDisabled: true
              },
              {
                  label: this.$t('SEASON'),
                  prop: 'Season',
                  width: 120,
                  filterable: true,
                  dicData: this.seasonData,
                  editDisabled: true,
                  hide: !this.isCanAgent,
              },
            {
              label: this.$t('STATE'),
              prop: 'TrackingApproveStatus',  
              placeholder: ' ',
              type: "select",
              editDisplay: false,
              dicData: this.traingStatusData,
              slot: true
            },
            {
              label: this.$t('ACDate'),
              prop: 'ACDate',
              type: "date",
              placeholder: ' ',
              format: 'yyyy-MM-dd',
              valueFormat: 'yyyy-MM-dd',
              editDisabled: true,
              width: 120,
              hide: !this.isCanAgent,
            },
            {
              label: this.$t('ORDER DATE'),
              prop: 'OrderDate',
              type: "date",
              placeholder: ' ',
              format: 'yyyy-MM-dd',
              valueFormat: 'yyyy-MM-dd',
              editDisabled: true,
              sortable: true,
              width: 120,
            },
            {
              label: this.$t('UPDATE TIME'),
              prop: 'LinkageUpdateDateTime',
              type: "date",
              editDisplay: false,
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat: "yyyy-MM-dd HH:mm:ss",
              sortable: true,
              width: 130,
            },
            {
              label: this.$t('PODivision'),
              placeholder: ' ',
              prop: 'Division',
              hide: true,
              editDisabled: true
            }
                        ]
                    },
                    tableOptFile: {
                        page: false,
                        refreshBtn: false,
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        menu: false,
                        emptyText: this.$t('No Data'),
                        labelPosition: 'top',
                        menuWidth: 200,
                        align: 'center',
                        columnBtn: false,
                        simplePage: true,
                        expand: false,
                        rowKey: 'ExtNo',
                        expandRowKeys: [],
                        column: [
                            {
                                label: this.$t('REMARKS'),
                                prop: 'remarkShow',
                                slot: true
                            },
                            {
                                label: this.$t('BUSINESS SERIAL NUMBER EXTERNAL'),
                                prop: 'extNo',
                            },
                            {
                                label: this.$t('PO'),
                                prop: 'po',

                            },
                            {
                                label: this.$t('VendorSection'),
                                prop: 'vendorSectionId',
                            },
                            {
                                label: this.$t('Supplier Code'),
                                prop: 'supplierCode',
                            },
                            {
                                label: this.$t('Supplier'),
                                prop: 'supplierName',
                            },
                            {
                                label: this.$t('Batch'),
                                prop: 'batch',
                            },
                            {
                                label: this.$t('Brand'),
                                prop: 'brand',
                            },
                            {
                                label: this.$t('Division'),
                                prop: 'division',
                            },
                            {
                                label: this.$t('ORDER DATE'),
                                prop: 'orderDate',
                            },
                            {
                                label: this.$t('ContractNo'),
                                prop: 'contractId',
                            },
                            {
                                label: this.$t('Item Batch'),
                                prop: 'itemBatch',
                            },
                            {
                                label: this.$t('Product Name'),
                                prop: 'productName',
                            },
                            {
                                label: this.$t('Business Code'),
                                prop: 'code',
                            },
                            {
                                label: this.$t('COLOR'),
                                prop: 'color',
                            },
                            {
                                label: this.$t('Number'),
                                prop: 'number',
                            },
                            {
                                label: this.$t('UNIT'),
                                prop: 'unit',
                            },
                            {
                                label: this.$t('ORIGIN'),
                                prop: 'origin',
                            },
                            {
                                label: this.$t('FIBER TYPE'),
                                prop: 'fiberType',
                            },
                            {
                                label: this.$t('Affiliated Corporation Collection'),
                                prop: 'affiliatedCorpCollection',
                            },
                            {
                                label: this.$t('Affiliated Corporation Working'),
                                prop: 'affiliatedCorpWorking',
                            },
                            {
                                label: this.$t('ACDate'),
                                prop: 'aCDate',
                            },
                            {
                                label: this.$t('COUNTRY OF DESTINATION'),
                                prop: 'destinationCountry',
                            }

          ]
        }
      }
    },
    setDataComponent() {
      var valiCategoryExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.objComponent.id;
          if (this.objComponent.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("SaleComponent_IsCategoryExist", ids, value, this.soDetailId).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('CATEGORYS') + " " + this.$t('Exist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      var valiRawFiberTypeIdExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.objComponent.id;
          if (this.objComponent.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("SaleComponent_IsRawFiberTypeIdExist", ids, value, this.parentId).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('FIBER TYPE') + " " + this.$t('Exist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      var valiNumbPass = (rule, value, callback) => {
        if (Number(value) > 0) {
          callback();
        } else {
          callback(new Error(this.$t('NeedGreater0')));
        }
      };
      var valiRatio = (rule, value, callback) => {
        if (Number(value) > 100) {
          callback(new Error(this.$t('Cannot be greater than 100')));
        } else {
          callback();
        }
      };
      return {
        tableOpt: {
          page: true,
          refreshBtn: false,
          delBtn: false,
          emptyText: this.$t('No Data'),
          menuTitle: this.$t('OPERATION'),
          menuWidth: 140,
          align: 'center',
          columnBtn: false,
          editTitle: this.$t('Edit'),
          addTitle: this.$t('Add'),
          addBtn: false,
          editBtn: false,
          header: true,
          labelPosition: 'top',
          simplePage: true,
          border: true,
          index: true,
          defaultExpandAll: true,
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          rowKey: 'Id',
          column: [
            {
              label: this.$t('CATEGORYS'),
              prop: 'Category',
              formslot: true,
              placeholder: ' ',
              editDisplay: this.isParent,//页面 隐藏
              addDisplay: this.isParent,
              rules: [{ required: true, message: this.$t('Required'), trigger: 'blur' },
                { validator: valiCategoryExist, trigger: 'blur' }]
            },
            {
              label: this.$t('FIBER TYPE'),
              prop: 'RawFiberTypeId',
              placeholder: ' ',
              editDisplay: !this.isParent,//页面 隐藏
              addDisplay: !this.isParent,
              type: "select",
              filterable: true,
              dicData: this.rawFiberData,
              rules: [{ required: true, message: this.$t('FIBER TYPE'), trigger: 'blur' },
                { validator: valiRawFiberTypeIdExist, trigger: 'blur' }]
            },
            {
              label: this.$t('RATIO'),
              prop: 'Ratio',
              placeholder: ' ',
              type: 'number',
              editDisplay: !this.isParent,//页面 隐藏
              addDisplay: !this.isParent,
              rules: [
                { type: "number", required: true, validator: valiNumbPass, trigger: 'blur' },
                { type: "number", validator: valiRatio, trigger: 'blur' }]
            },
            {
              label: this.$t('REMARK'),
              placeholder: ' ',
              prop: 'Remarks',
            },
            {
              label: this.$t('STATE'),
              prop: 'Status',
              hide: true,
              editDisplay: false,//页面 隐藏
              addDisplay: false,
            },
            {
              label: this.$t('STATE'),
              prop: 'SoDetailId',
              hide: true,
              editDisplay: false,//页面 隐藏
              addDisplay: false,
            },
            {
              label: this.$t('STATE'),
              prop: 'ParentId',
              hide: true,
              editDisplay: false,//页面 隐藏
              addDisplay: false,
            },
          ]
        },
      }
    }
  },
  created() {
    this.init()
  },
  watch: {
    $route() {
      this.init()
    }
  },
        methods: {
            getSelectBadge() {
                this.selectBadge = 0;
                if (this.codeVal != "") {
                    ++this.selectBadge;
                };
                if (this.businessnooutVal != "") {
                    ++this.selectBadge;
                };
                if (this.businessnoinVal != "") {
                    ++this.selectBadge;
                };
                if (this.batchVal != "") {
                    ++this.selectBadge;
                };
                if (this.colorVal != "") {
                    ++this.selectBadge;
                };
                if (this.soVal != "") {
                    ++this.selectBadge;
                };
                if (this.beginTimeVal != "") {
                    ++this.selectBadge;
                };
                if (this.endTimeVal != "") {
                    ++this.selectBadge;
                };
                if (this.UTbeginTimeVal != "") {
                    ++this.selectBadge;
                };
                if (this.UTendTimeVal != "") {
                    ++this.selectBadge;
                };
                if (this.fromVal.ids.length != 0) {
                    ++this.selectBadge;
                };
                if (this.otherStatusVal != null) {
                    ++this.selectBadge;
                };
                if (this.traingStatusVal != null) {
                    ++this.selectBadge;
                };
                if (this.selectBadge == 0) {
                    this.selectBadge = null;
                }
            },
      cellStyle({ row, column, rowIndex, columnIndex }) {
            if (row.IsUntreated) {
                return {
                    fontWeight: 'bold'
                }
            }            
        },
    tableRowClassName({ row, rowIndex }) {
      if (row.CopySign === 2) {
        return 'warning-row';
      }
      return '';
    },
    checkCancelBtn(row) { /*取消复制*/
      this.loading = true;
      PutSaleOrderDetail_CancelCopy(row.Id).then(() => {
        this.getSaleOrderList();
      })
    },
    cancelCopy(row, data) { /*copy 取消*/
      data.forEach((f) => {
        f.CopyDeclarationbox1 = true;
        f.checkBox = true
      });
    },
    preservationCopy(row, rowData) { /*copy 保存*/
      row.CopyDeclarationbox1 = true
      this.loading = true;
      var data = {
        id: row.Id,
        copyIds: [row.Id]
      }

      rowData.forEach((f) => {
        if (row.Id == f.CopyDeclarationId) {
          data.copyIds.push(f.Id)
        }
      });

      PutSaleOrderDetail_Copy(data).then(() => {
        // console.log(response);
        this.getSaleOrderList();
      })
    },
    checkBtn(row, data) {
      data.forEach((f) => {
        if (!f.checkBox) {
          if (row.CopyDeclarationId) {
            console.log('剔除')
            row.CopyDeclarationId = ''
            f.fromData.forEach((z, index) => {
              console.log(z, row.Id)
              if (z == row.Id) {
                f.fromData.splice(index, 1)
              }
            });
          } else {
            console.log('加入')
            f.fromData.push(row.Id)
            row.CopyDeclarationId = f.Id
          }
        }
      })
    },
    CopyDeclarationBtn(row, data, index) {
      data.forEach((f, findex) => {
        if (f.CopyDeclarationId == row.Id) {
          f.checkBoxStatus = true;
        } else {
          if (f.CopyDeclarationId) {
            f.checkBoxStatus = false;
          }
        }
        // console.log(f.CopyDeclarationId, findex, row.Id)
        f.CopyDeclarationbox1 = false;
      });
      row.CopyDeclarationId = row.Id;
      row.checkBox = false
      row.CopyDeclarationbox1 = false;
    },
    CopyDeclarationStatus(length, CopySign) {
      if (length == 1) {
        return false;
      }

      return CopySign == 0 ? true : CopySign == 1 ? true : false
    },
    init() {
      //排序说明三位分别是：创建时间，订单时间，联动更新时间
      //每位值为：0-升序，1-降序，2-无
      this.sortVal = "122"
      this.getSaleOrderList();
      TracingDropDownList('all_VendorSectionList').then(res => {
        this.sectionData = res.data;
      });
      TracingDropDownList('FromList').then(res => {
        this.fromData = res.data;
      });
      ProcessDropDownList('ProductList').then(res => {
        this.productData = res.data;
      });
      // 关联公司TYPE--0: 全部信息1：实际收款, 2实际付款，3实际加工
      TracingDropDownList('AffiliatedCorp', '', 1).then(res => {
        this.affCorpCollectionData = res.data;
      });
      TracingDropDownList('AffiliatedCorp', '', 3).then(res => {
        this.affCorpWorkingData = res.data;
      });
      ProcessDropDownList('AllDictList', 'brand').then(res => {
        this.BrandData = res.data;
      });
      ProcessDropDownList('AllDictList', 'season').then(res => {
          this.seasonData = res.data;
      });
      TracingDropDownList('RawFiberType').then(res => {
        this.rawFiberData = res.data;
      });
      TracingUnifiedIsBool("BrandBusiness_AgenEntryPoExist").then(res => {
        this.isCanAgent = res.data;
      })
      TracingDropDownList('DestinationCountryData').then(res => {
        this.destinationCountryData = res.data;
      });

      corpinfo().then(res => {
        this.corpId = res.data.id;
      })
    },
    //设置表头行的样式
    tableHeaderColor() {
      return 'background-color:#838bb3!important;padding: 0!important;'

    }, //表格行央视
    rowStyle({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
          obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        } else {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        }
      }
            },//搜索框失去焦点 
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
            obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
        }
      } else if (obj.currentTarget === null) {
          if (this.fromVal.ids.length === 0) {
            document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
        }
          if (this.otherStatusVal == null || this.otherStatusVal == '') {
              document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
          }
          if (this.traingStatusVal == null || this.traingStatusVal == '') {
              document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
          }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }
    },
    formatterColumn(row) {
        var s_Status = this.GetStatusName(row.TrackingApproveStatus);
      return s_Status;
    },
    formatterTrackingStatus(row) {
      var s_Status = this.GetStatusName(row.TrackingApproveStatus);
      return s_Status;
    },

    venchang() {
      if (this.fromVal.ids.length === 0) {
        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//点击更多按钮
    otherStatuschang() {
      if (this.otherStatusVal === 0) {
        document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#otherStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//点击更多按钮
    traingStatuschang() {
      if (this.traingStatusVal === 0) {
        document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#traingStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//点击更多按钮
    moreFrom() {
      let fromHei = document.querySelector(".fromOutDiv").clientHeight;
      if (fromHei > 60) {
        document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
      } else {
        document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
      }
    },
    //编辑数据
    handleUpdate(row, index, done) {
      editSale(row).then(() => {
        this.$message({
          showClose: true,
          message: this.$t('UpdateSuccess'),
          type: "success"
        });
        this.getSaleOrderList();
        done();
      })
    },
    selectionChange(list) {
      list != "" ? this.disabled = false : this.disabled = true;
    },
    search() {
      this.getSaleOrderList();
    },
    currentChange(val) {
      this.page.currentPage = val
      this.getSaleOrderList();
    },
    async getSaleOrderList() {
        await saleOrderList(this.page.currentPage, this.page.pageSize, this.businessnoinVal, this.businessnooutVal, this.soVal, this.fromVal.ids.toString(), this.codeVal, this.batchVal, this.colorVal, this.comVal, this.beginTimeVal, this.endTimeVal, this.otherStatusVal, this.traingStatusVal, this.UTbeginTimeVal, this.UTendTimeVal, this.sortVal, this.SeaCodeVal).then(res => {
        this.loading = false;
        res.data.SaleOrders.forEach((f, index) => {
          f.Details.forEach((f1) => {
            f1.parentIndex = index;
            f1.CopyDeclarationbox = true;
            f1.CopyDeclarationbox1 = true;
            f1.checkBox = true;
            f1.checkBoxStatus = true;
            f1.fromData = []
          })
        })
        this.tableData = res.data.SaleOrders;
        this.page.total = res.data.TotalCount;
        this.page.pageSize = res.data.PageSize;
      }).catch(erro => {
        console.log(erro)
      })
    },//列表
    rele(row) {
      if (row.Status == 1) {
        this.$message({ showClose: true, message: this.$t('NotCanDeleted'), type: "warning" });
        return;
      }
      this.$router.push({ path: '/pvh_factory/materialOrd', query: { soDetailid: row.Id, soid: row.SaleOrderId } })
    },//上传
    upload(row) {
      this.vendorSectionId = row.VendorSectionId;
      //验证此销售单是否有SO及成分
      TracingUnifiedIsBool('IsWriteSOComponent', row.Id).then(res => {
        var IsExist = res.data;
        if (!IsExist) {
          this.$message({
            message: this.$t('Please SO filled in'),
            type: "error"
          });
        }
      });
      //上传前判断是否有品牌商信息
      TracingDropDownList('OrderBrandTrackList', row.PurchaserOrderDetailId).then(res => {
        this.brandList = res.data;
        if (this.brandList.length > 0) {
          if (this.brandList.length == 1) {
            this.$router.push({
              path: '/pvh_factory/declarationEntry',
              query: {
                orderId: row.Id,
                brandBusinessId: this.brandList[0].value,
                vendorSectionId: this.vendorSectionId,
                isPO: 'false'
              }
            })
          } else {
            this.soDetailId = row.Id;
            this.BranddialogTableVisible = true;
          }
        } else {
          this.$message({
            message: this.$t('NoBrandInfo'),
            type: "warning"
          });
          return;
        }
      });
    },
    submitBrandChoose() {
      if (this.brandChoose == "") {
        this.$message({
          message: this.$t('PleaseChooseBrand'),
          type: "warning"
        });
        return;
      } else {
        this.$router.push({
          path: '/pvh_factory/declarationEntry',
          query: {
            orderId: this.soDetailId,
            brandBusinessId: this.brandChoose,
            vendorSectionId: this.vendorSectionId,
            isPO: 'false'
          }
        })
      }

    },//提交修改项
    async getSaleOrderListExcel(ExportAllVal) {
        this.downUrl = pvhApiBase + "Tracing/GetSaleOrdersExcel?page=" + this.page.currentPage + "&pageSize=" + this.page.pageSize + "&businessnoin=" + this.businessnoinVal + "&businessnoout=" + this.businessnooutVal + "&so=" + this.soVal + "&from=" + this.fromVal.ids.toString() + "&code=" + this.codeVal + "&batch=" + this.batchVal + "&color=" + this.colorVal + "&completion=" + this.comVal + "&beginOrderDate=" + this.beginTimeVal + "&endOrderDate=" + this.endTimeVal + (this.otherStatusVal == null ? "" : "&otherStatus=" + this.otherStatusVal) + (this.traingStatusVal == null ? "" : "&traingStatus=" + this.traingStatusVal) + "&UTbegin=" + this.UTbeginTimeVal + "&UTend=" + this.UTendTimeVal + "&sort=" + this.sortVal  + "&SeaCode=" + this.SeaCodeVal + "&exportAll=" + ExportAllVal;
      let dt = new Date();
      var filename = dt.getFullYear() + '-' +
          ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
          (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
          (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
          (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
          (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
      this.downloadExcel(this.downUrl, "so" + filename);
    },
    exportOrder() {
      this.dialogExportVisible = true;
      //this.$confirm(this.$t('Maximum 10000 orders'), this.$t('Tips'), {
      //    confirmButtonText: this.$t('Yes'),
      //    cancelButtonText: this.$t('Cancel'),
      //    type: "warning"
      //}).then(() => {
      //    this.getSaleOrderListExcel();
      //})
    },
    exportCurrent() {
      this.dialogExportVisible = false;
      this.getSaleOrderListExcel(false);
    },
    exportAll() {
      this.dialogExportVisible = false;
      this.getSaleOrderListExcel(true);
    },
    downloadExcel(url, fileName) {
      fetch(url, {
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'corpId': getGrop()
        }
      }).then(function (response) {
        if (response.ok) {
          return response.arrayBuffer()
        }
        throw new Error('Network response was not ok.')
      }).then(function (arraybuffer) {
        let blob = new Blob([arraybuffer], {
          type: `application/vnd.ms-excel`
        })
        let objectURL = URL.createObjectURL(blob)
        let downEle = document.createElement('a')
        let fname = fileName // 下载文件的名字
        // let fname = `download` // 下载文件的名字
        downEle.href = objectURL
        downEle.setAttribute('download', fname + '.xlsx')
        document.body.appendChild(downEle)
        downEle.click()
      }).catch(function (error) {
        console.log('There has been a problem with your fetch operation: ', error.message)
      })
    },
            formatterColumnRemark(e) {
                return e.remark;
            },
    editSOinfo(row) {
      this.SoDetaildialogTableVisible = true;
      this.SoDetailEdit.SoId = row.SaleOrderId;
      this.SoDetailEdit.SoDetailId = row.Id;
      this.SoDetailEdit.OutProductName = row.OutProductName;
      this.SoDetailEdit.OutProductCode = row.OutProductCode;
      this.SoDetailEdit.ProductCode = row.ProductCode;
      this.SoDetailEdit.ProductName = row.ProductName;
      this.SoDetailEdit.Number = row.Number;
      this.SoDetailEdit.UnitName = row.UnitName;
      this.SoDetailEdit.Color = row.Color;
      this.SoDetailEdit.Ingredient = row.Ingredient;
      this.SoDetailEdit.Remark = row.Remark;
      this.SoDetailEdit.Code = row.Code;
      this.SoDetailEdit.Batch = row.Batch;
      this.SoDetailEdit.UnitName = row.UnitName;
      this.SoDetailEdit.BusinessNoIn = row.BusinessNoIn;
      this.SoDetailEdit.BusinessNoOut = row.BusinessNoOut;
      var ApproveStatus = row.ApproveStatus;
      var LockedStatus = row.LockedStatus;
      if ((ApproveStatus <= 670 && ApproveStatus >= 650) || LockedStatus == 605) {
        this.isLocked = true;
      }
    },//弹出修改框
    submitInfoSO() {
      let sorow = {
        Id: this.SoDetailEdit.SoId,
        DetailId: this.SoDetailEdit.SoDetailId,
        So: "",
        ProductCode: this.SoDetailEdit.ProductCode,
        ProductName: this.SoDetailEdit.ProductName,
        Remark: this.SoDetailEdit.Remark,
        BusinessNoOut: this.SoDetailEdit.BusinessNoOut,
      };
      editDetailSO(sorow).then(() => {
        this.$message({
          message: this.$t('OperationSuccess'),
          type: "success"
        });
        this.getSaleOrderList();
      }).catch((erro) => {
        console.log(erro)
      });

    },//提交修改项
    selectChanged(value) {
      var objrow = {};
      objrow = this.productData.find((i) => {//这里的List就是上面遍历的数据源
        return i.value === value;//筛选出匹配数据
      });
      if (objrow == undefined) {
        var Productid = Getguid();
        var Productcode = value;
        var Productname = value;
        //创建物料接口
        let paramrow = {
          id: Productid,
          name: Productname,
          code: Productcode,
          status: 0,
          enableBatch: 1,
          corporationId: this.corpId,
          auditStatus: 1,
          isStandard: 0,
          isAutomaticAdd: 1,
        };
        ProcessAddProduct(paramrow).then(res => {
              let newItem = { value: Productid, label: Productname };
              this.productData.unshift(newItem);
              this.SoDetailEdit.ProductCode = Productid;
              this.SoDetailEdit.ProductName = Productname;
            }
        ).catch((erro) => {
          console.log(erro)
        });
      } else {
        this.SoDetailEdit.ProductName = objrow.label;
      }
    },
    fibred(row) {
      var ApproveStatus = row.ApproveStatus;
      var LockedStatus = row.LockedStatus;
      if ((ApproveStatus <= 670 && ApproveStatus >= 650) || LockedStatus == 605) {
        this.isLocked = true;
      }
      this.fibredialogTableVisible = true;
      this.soDetailId = row.Id;
      this.ApproveStatus = row.ApproveStatus;
      this.CategoryData = [];
      TracingStringList("ClassMainRawMaterials", row.VendorSectionId).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.CategoryData.push({
            value: res.data[i]
          })
        }
      });
      this.GetList();
    },
    currentComponentChange(val) {
      this.pageComponent.currentPage = val;
      this.GetList();
    },//搜索
    searchFrom() {
      this.GetList();
    },//编辑数据
    handleComponentUpdate(row, index, done) {
      if (this.ApproveStatus == 320) {
        this.$confirm(this.$t('Please resubmit the audit application again if you need to edit the audited data.'), this.$t('Tips'), {
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('Cancel'),
          type: "warning"
        }).then(async (action) => {
          if (action !== "confirm") {
            return false;
          }
          else {
            ReducingStateSubmitAudit(this.soDetailId, "").then(() => { });
            this.after_edit(row);
            done();
          }
        });
      }
      else {
        this.after_edit(row);
        done();
      }
    },//添加数据
    after_edit(row) {
      edit(row).then(() => {
        this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
        this.GetList();
        this.objComponent = {};
        this.getSaleOrderList();
      })
    },
    after_add(row) {
      row.SoDetailId = this.soDetailId;
      row.Status = 0;
      row.ParentId = this.parentId
      add(row).then(() => {
        this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
        this.GetList();
        this.objComponent = {};
        this.getSaleOrderList();
      })
    },
    after_del(row) {
      del(row.Id).then(() => {
        this.GetList();
        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
        this.getSaleOrderList();
      })
    },
    handleSave(row, done) {
      if (this.ApproveStatus == 320) {
        this.$confirm(this.$t('Please resubmit the audit application again if you need to edit the audited data.'), this.$t('Tips'), {
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('Cancel'),
          type: "warning"
        }).then(async (action) => {
          if (action !== "confirm") {
            return false;
          }
          else {
            ReducingStateSubmitAudit(this.soDetailId, "").then(() => { });
            this.after_add(row);
            done();
          }
        });
      }
      else {
        this.after_add(row);
        done();
      }
    },
    //删除数据
    handDel(row, done) {
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: "warning"
      }).then(() => {
        if (this.ApproveStatus == 320) {
          this.$confirm(this.$t('Please resubmit the audit application again if you need to edit the audited data.'), this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: "warning"
          }).then(async (action) => {
            if (action !== "confirm") {
              return false;
            }
            else {
              ReducingStateSubmitAudit(this.soDetailId, "").then(() => { });
              this.after_del(row);
            }
          });
        }
        else {
          this.after_del(row);
        }
      })
    },//获取列表显示出数据
    async GetList() {
      List(this.pageComponent.currentPage, this.pageComponent.pageSize, this.soDetailId).then(res => {
        this.tableDataComponent = res.data.SaleComponents;
        this.pageComponent.total = res.data.TotalCount;
        this.pageComponent.pageSize = res.data.PageSize;
        if (this.tableDataComponent.length == 0) {
          let paramrow = {
            Id: '',
            SoDetailId: this.soDetailId,
            ParentId: "0",
            Category: "Default",
            RawFiberTypeId: "",
            Ratio: 0,
            Remarks: "",
            Status: 0,
          };
          this.tableDataComponent.push(paramrow);
        }
      }).catch(erro => {
        console.log(erro);
      })
    },
    addPurOrd() {
      this.$router.push({
        path: '/pvh_factory/purchaseAdd_AgenEntryPo',
        query: { sign: 'add', ID: '', corpid: '', systemBatchNo: '' }
      })
    },//新增数据
    editPur(row) {
      this.$router.push({
        path: '/pvh_factory/purchaseAdd_AgenEntryPo',
        query: { sign: 'edit', ID: row.PurchaserOrderId, corpid: row.PurchaseCorporationId, systemBatchNo: '' }
      })
    },
    ViewAuditInfo(row) {
      var info = row;
      this.RejectdialogTableVisible = true;
      this.RejectEdit.DetailOrderId = info.Id;
      this.RejectEdit.Remarks = '';
      this.getRejectList();
      if (info.ApproveStatus > 605) {
        this.isViewAudit = true;
      } else {
        this.isViewAudit = false;
      }
    },
    submitReject(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramrow = {
            detailOrderId: this.RejectEdit.DetailOrderId,
            remarks: this.RejectEdit.Remarks,
            status: 550,
          };
          addResubmitsSo(paramrow).then(() => {
            this.$message({
              message: this.$t('OperationSuccess'),
              type: "success"
            });
            this.RejectdialogTableVisible = false;
            this.GetList();
          }).catch((erro) => {
            console.log(erro)
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async getRejectList() {
      await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
        var RejectInfo = res.data.OrderApproves;
        this.tableDataRejectList = RejectInfo;
        this.pageRejectList.total = res.data.TotalCount;
        this.pageRejectList.pageSize = res.data.PageSize;
      }).catch(erro => {
        console.log(erro)
      })
    },
    currentRejectListChange(val) {
      this.pageRejectList.currentPage = val
      this.getRejectList();
    },
    //追踪
    tracingPur(row) {
      this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: row.PurchaserOrderDetailId } })
    },
    formatColumn(e) {
      var helpReturn = e;
      if (helpReturn == "NoUpinfo") {
        return this.$t('NoUpinfo');
      } else if (helpReturn== "HavePartUpinfo") {
        return this.$t('HavePartUpinfo');
      } else if (helpReturn== "NoWriteSO") {
        return this.$t('NoWriteSO');
      } else if (helpReturn == "HaveWriteSO") {
        return this.$t('HaveWriteSO');
      } else if (helpReturn == "NoWriteComponent") {
        return this.$t('NoWriteComponent');
      } else if (helpReturn== "HaveWriteComponent") {
        return this.$t('HaveWriteComponent');
      } else if (helpReturn == "NoRelation") {
        return this.$t('NoRelation');
      } else if (helpReturn == "HaveRelation") {
        return this.$t('HaveRelation') ;
      } else {
        return "";
      }
    },
    SubmitAudit(row) {
      this.$confirm(this.$t('Please confirm whether to submit for approval') + row.BusinessNoIn, this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: "warning"
      }).then(() => {
        SoSubmitAudit(row.Id, row.PurchaserOrderDetailId).then(() => {
          this.getSaleOrderList();
          this.$message({ showClose: true, message: this.$t('OperationSuccess'), type: "success" });
        });
      });
    },
    delPur(row) {
      console.log("row", row);
      //如果使用的数据不能删除
      SoListByPoId(row.PurchaserOrderId).then(res => {
        var list = res.data;
        if (list.length > 0) {
          this.$message({ showClose: true, message: this.$t('NotCanDeletedPO'), type: "warning" });
          return;
        }
        this.$confirm(this.$t('IsDel') + row.PurchaserPo, this.$t('Tips'), {
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('Cancel'),
          type: "warning"
        }).then(() => {
          delHead(row.PurchaserOrderId).then(() => {
            this.getSaleOrderList();
            this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
          });
        });
      })
    },//恢复数据
    SearchCategoryInfo(queryString, cb) {
      var restaurants = this.CategoryData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results.slice(0, 10));
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //替换表格时间格式
    formatDate(row, column) {
      // 获取单元格数据
      let date = row[column.property];
      if (date) {
        return this.transformDate(date)
      } else {
        return ''
      }
    },
    //标准时间格式转yyyy-MM-dd HH:mm:ss
    transformDate(date) {
      if (date) {
        let dt = new Date(date);
        return dt.getFullYear() + '-' +
            ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
            (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
            (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
            (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
            (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
      } else {
        return ''
      }
    },
    addHeadComponent(row) {
      this.isParent = false;
      if (row.Id == "") {
        let paramrow = {
          id: '',
          soDetailId: this.soDetailId,
          parentId: "0",
          category: "Default",
          rawFiberTypeId: "",
          ratio: 0,
          remarks: "",
          status: 0,
        };
        add(paramrow).then(res => {
          this.parentId = res.data.result;
        })
      } else {
        this.parentId = row.Id;
      }

    },
    sortChange(val) {
      //排序说明三位分别是：创建时间，订单时间，联动更新时间
      //每位值为：0-升序，1-降序，2-无
      if (val.prop == "OrderDate") {
        if (val.order == "ascending") {
          this.sortVal = "212";

        } else {
          this.sortVal = "202";
        }
      } else if (val.prop == "LinkageUpdateDateTime") {
        if (val.order == "ascending") {
          this.sortVal = "221";
        } else {
          this.sortVal = "220";
        }
      }
      this.saleOrderList();

    },
    //给状态信息
    GetStatusName(statusCode) {
      var s_Status = "";
      switch (statusCode) {
          case -1:
              s_Status = this.$t('Delete');
              break;
          case 0:
              s_Status = this.$t('Ordering');
              break;
          case 110:
              s_Status = this.$t('Ordering');
              break;
          case 120:
              s_Status = this.$t('Ordering');
              break;
          case 130:
              s_Status = this.$t('Ordering');
              break;
          case 140:
              s_Status = this.$t('Ordering');
              break;
          case 150:
              s_Status = this.$t('Ordering');
              break;
          case 160:
              s_Status = this.$t('Under Declaration');
              break;
          case 320:
              s_Status = this.$t('Submitted');
              break;
          case 410:
              s_Status = this.$t('Rejected');
              break;
          case 550:
              s_Status = this.$t('Resubmitted');
              break;
          case 605:
              s_Status = this.$t('Locked');
              break;
          case 660:
              s_Status = this.$t('Approved');
              break;
          case 670:
              s_Status = this.$t('Reviewed');
              break;
          default:
              s_Status = this.$t('unknown');
      }
      return s_Status;
            },
            //替换表格时间格式
            formatDate(row, column) {
                // 获取单元格数据
                let date = row[column.property];
                if (date) {
                    return this.transformDate(date)
                } else {
                    return ''
                }
            },
            //标准时间格式转yyyy-MM-dd HH:mm:ss
            transformDate(date) {
                if (date) {
                    let dt = new Date(date);
                    return dt.getFullYear() + '-' +
                        ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                        (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                        (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                        (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                        (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
                } else {
                    return ''
                }
            },
            submitSelectData() {
                this.$confirm(this.$t('IsImportPO'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    this.dialogTableVisible = false;
                    this.dialogImportTableVisible = false;
                    this.fileList.forEach(file => {
                        let formData = new FormData(); //  用FormData存放上传文件
                        formData.append("file", file); //文件
                        this.$axios({
                            method: "post",
                            url: pvhApiBase + "Tracing/ImportAgentFileOrderList",
                            headers: {
                                'Content-type': 'multipart/form-data'
                            },
                            data: formData
                        }).then(
                            res => {
                                console.log("ImportAgentFileOrderList", res.data);
                                this.getSaleOrderList();
                                this.downUrl = pvhApiBase + "Tracing/GetExcelFilePathStream?filepath=" + res.data;
                                let dt = new Date();
                                var filename = dt.getFullYear() + '-' +
                                    ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                                    (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                                    (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                                    (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                                    (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
                                this.downloadExcel(this.downUrl, "poimportresult" + filename);
                            }, err => {
                                console.log(err);
                            });

                    });
                })
            },
            openimport() {
                this.dialogImportTableVisible = true;
                this.fileList = [];
            },
            importOrder() {
                if (this.fileList.length === 0) {
                    this.$message.warning(this.$t('Please upload the file'));
                } else {
                    this.fileList.forEach(file => {
                        let formData = new FormData(); //  用FormData存放上传文件
                        formData.append("file", file); //文件
                        this.$axios({
                            method: "post",
                            url: pvhApiBase + "Tracing/GetAgentFileOrderList",
                            headers: {
                                'Content-type': 'multipart/form-data'
                            },
                            data: formData
                        }).then(
                            res => {
                                console.log("GetAgentFileOrderList", res.data);
                                this.tableFileData = res.data;
                                this.dialogTableVisible = true;
                                this.dialogImportTableVisible = false;
                            }, err => {
                                console.log(err);
                            });
                    });
                }
            },
            downTemplate() {
                this.downUrl = pvhApiBase + "Tracing/GetPOTemplateExcelFilePathStream";
                this.downloadExcel(this.downUrl, "ImportPOTemplate");
            },
            uploadClick() {

            },
            beforeUpload(file, fileList) {
                var extension = file.name.split('.').pop().toLowerCase();
                if (extension !== 'xls' && extension !== 'xlsx') {
                    this.$message.warning('只能上传后缀是.xls和.xlsx的文件');
                    return;
    }
                // 文件大小限制为10M
                const fileLimit = file.size / 1024 / 1024 > 10;
                if (fileLimit) {
                    setTimeout(() => {
                        this.$message.error(this.$t('UploadFailed') + ":[" + file.name + "]," + this.$t('UploadRestrictionsC'));
                    }, 1000);
                    return;
                }
                this.fileList = [];
                this.fileList.push(file.raw);
            },
            handleRemove(file) {
                if (this.isLocked) {
                    this.$message({ message: this.$t('cantdelete'), type: "error" });
                    return false;
                }
                if (file.status == "success") {
                    this.fileList = [];
                    this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    //delfile(file.uid).then(() => {
                    //    this.file = null;
                    //    //待删除
                    //    this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    //});
                }
            },
            beforeRemove(file) {
                if (this.isLocked) {
                    this.$message({ message: this.$t('cantdelete'), type: "error" });
                    return false;
                }
                return this.$confirm(this.$t('IsDel') + file.name, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                })
            },
            handlePreview(file) {

            },
  }
}
</script>
